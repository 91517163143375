import {on} from 'delegated-events'
import {observe} from 'selector-observer'

on('click', '[data-copy-to-input]', (event) => {
  event.preventDefault()

  const targetInput = document.querySelector('[data-copy-to-input-target]') as HTMLInputElement

  targetInput.value = event.currentTarget.getAttribute('data-copy-to-input')
  targetInput.closest('form').querySelector<HTMLButtonElement>('[name=commit]').click()
})

observe('[data-ai-chat-thread]', {
  add(el) {
    //Scroll down
    el.scrollIntoView({behavior: 'smooth'})
  },
})
