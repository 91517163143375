// function that infers video height based on window screen dimensions
export function secondsToTime(seconds: number) {
  if (seconds <= 60) {
    return `0:${formatTime(seconds)}`
  } else if (seconds <= 3600) {
    return `${formatTime(seconds / 60, false)}:${formatTime(seconds % 60)}`
  } else {
    return `${formatTime(seconds / 3600, false)}:${formatTime((seconds % 3600) / 60)}:${formatTime(
      (seconds % 3600) % 60
    )}`
  }
}

function formatTime(number: number, prepend0 = true) {
  const r = Math.floor(number)
  return r < 10 && prepend0 ? `0${r}` : r
}

export async function afterComponentConnects(componentName: string, callback: (arg0: unknown) => void, timeout = 2000) {
  const result = await Promise.race([
    window.customElements.whenDefined(componentName),
    new Promise((resolve) => setTimeout(resolve, timeout, 'TIMEOUT')),
  ])
  if (result === 'TIMEOUT') {
    console.warn(`timed out ${timeout}ms after waiting on ${componentName} to connect`)
    return
  }
  if (callback) callback(result)
}

export function stringToDecimal(str: string, decimals: number) {
  return parseFloat(parseFloat(str).toFixed(decimals))
}

const formFieldTags = ['INPUT', 'SELECT', 'TRIX-EDITOR']

export function isFormField(target: Element): boolean {
  if (!(target instanceof HTMLElement)) return
  if (formFieldTags.includes(target.tagName)) return true
  return false
}
