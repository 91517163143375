import {secondsToHms} from '../time'
import {on} from 'delegated-events'

// Markup:
//
// <input type="checkbox"
//        data-share-time-input-id="sc-input-id"
//        data-share-time-label-id="sc-label-id"
//        data-video-id="sc-video-id">
//
// <span id="sc-label-id" data-update-time-text>0:00</span>
// <input type="url" id="sc-input-id" value="https://rewatch.rewatch.com/video/id">
// <video id="sc-video-id"></video>
on('change', 'input[data-share-time-input-id]', (event) => {
  const checkbox = event.currentTarget as HTMLInputElement
  const input = document.getElementById(checkbox.getAttribute('data-share-time-input-id')) as HTMLInputElement
  const video = document.getElementById(checkbox.getAttribute('data-video-id')) as HTMLVideoElement
  const url = new URL(input.value)
  const videoTimeInSec = Math.floor(video.currentTime)

  const timeLabel = document.getElementById(checkbox.getAttribute('data-share-time-label-id'))
  timeLabel.toggleAttribute('data-update-time-text', !checkbox.checked)

  if (checkbox.checked) {
    url.searchParams.set('t', videoTimeInSec.toString())
  } else {
    url.searchParams.delete('t')
    timeLabel.textContent = currentTimeToHms(video)
  }

  input.value = url.toString()
})

// Look for elements that care about video.currentTime and update `value` or `textContent`.
//
// Markup:
//
// <video id="sc-video-id"></video>
// video time label: <span data-video-id="sc-video-id" data-update-time-text>0:03</span>
// video time input: <input data-video-id="sc-video-id" data-update-time-value value="3">
on(
  'timeupdate',
  'video[id]',
  (event) => {
    const video = event.currentTarget as HTMLVideoElement

    for (const item of document.querySelectorAll(`[data-update-time-text][data-video-id="${video.id}"]`)) {
      if (item.getAttribute('data-update-time-text') === 'false') continue

      const format = item.getAttribute('data-update-time-text-format') || '%'

      // Update the current time in the data field so we can select it in CSS when it's 0.
      item.setAttribute('data-update-time-text-current-time', video.currentTime.toString())

      item.textContent = format.replace('%', currentTimeToHms(video))
    }

    for (const item of document.querySelectorAll<HTMLInputElement>(
      `input[data-update-time-value][data-video-id="${video.id}"]`
    )) {
      const form = item.closest(`form`)
      if (form?.contains(document.activeElement)) continue

      const value =
        item.getAttribute(`data-update-time-hms`) === `true` ? currentTimeToHms(video) : video.currentTime.toString()
      item.value = value
    }
  },
  {capture: true}
)

function currentTimeToHms(video: HTMLVideoElement): string {
  return secondsToHms(Math.floor(video.currentTime))
}
