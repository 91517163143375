import {on} from 'delegated-events'

on('dragenter', '.js-uploader-drop-area', (event) => {
  event.preventDefault()
  event.stopPropagation()
  event.currentTarget.classList.add('video-uploader--file-hover')
})
on('dragleave', '.js-uploader-drop-area', (event) => {
  event.preventDefault()
  event.stopPropagation()
  event.currentTarget.classList.remove('video-uploader--file-hover')
})
on('dragover', '.js-uploader-drop-area', (event) => {
  event.preventDefault()
  event.stopPropagation()
})
on('drop', '.js-uploader-drop-area', async (event) => {
  event.preventDefault()
  event.stopPropagation()
  const container = event.currentTarget
  container.classList.remove('video-uploader--file-hover')
  uploadFiles(container.closest('.js-uploader-container'), event.dataTransfer.files)
})

on('change', '.js-auto-upload-change', async (event) => {
  const container = event.currentTarget.closest('.js-uploader-container') as HTMLElement
  uploadFiles(container, container.querySelector<HTMLInputElement>(`input[type="file"]`).files)
})

let uploader: typeof import('./multipart_uploader') = null
async function uploadFiles(container: HTMLElement, files: FileList) {
  for (const file of files) {
    if (file.size > 50 * 1024 * 1024 * 1024 /*50gb*/) {
      alert('File is too large!')
      return
    }
  }
  container.querySelector(`.js-uploader-drop-area`)?.classList?.remove('border:2', 'bg:surface-blue-muted')
  container.querySelector(`.js-uploading-heading`).classList.remove('hidden')
  container.querySelector(`.js-video-uploader-content`).classList.add('hidden')
  container.querySelector(`.js-external-upload-options`)?.classList?.add('hidden')

  if (!uploader) uploader = await import(/* webpackChunkName: "multipart_uploader" */ './multipart_uploader')
  uploader.uploadFiles(container, files)
}
