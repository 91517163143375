import {Application} from '@hotwired/stimulus'
// add support for easily prefixing "debounced:" to stimulus action definitions
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import debounced from 'debounced'

const application = Application.start()
debounced.initialize([`input`])

// bound to window so we can dynamically import and register controllers outside of the main bundle
window.Stimulus = application
declare global {
  interface Window {
    Stimulus: Application
  }
}

export {application}
