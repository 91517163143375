// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs'
import {start as startActiveStorage} from '@rails/activestorage'
import '@scottjg/auto-complete-element'
import '@github/details-dialog-element'
import '@github/details-menu-element'
import '@github/include-fragment-element'
import '@github/clipboard-copy-element'
import {polyfills} from '@github/browser-support'

import 'form-request-submit-polyfill'

import '../behaviors/annotations'
import '../behaviors/auto-click'
import '../behaviors/auto-complete-element'
import '../behaviors/aria-notify'
import '../behaviors/back'
import '../behaviors/bulk-actions'
import '../behaviors/copy-to-clipboard'
import '../behaviors/datadisable-workaround'
import '../behaviors/date'
import '../behaviors/details-dialog-element'
import '../behaviors/details-dropdown'
import '../behaviors/dynamic-imports'
import '../behaviors/form-validation'
import '../behaviors/form'
import '../behaviors/hotkey'
import '../behaviors/horizontal-scroll'
import '../behaviors/link'
import '../behaviors/modal'
import '../behaviors/modifier-key'
import '../behaviors/poll'
import '../behaviors/recent-history'
import '../behaviors/scroll-to-element'
import '../behaviors/sidebar'
import '../behaviors/sortable'
import '../behaviors/state-classes'
import '../behaviors/tabs'
import '../behaviors/toggle'
import '../behaviors/tooltip'
import '../behaviors/transcripts'
import '../behaviors/transcript-toolbar'
import '../behaviors/turbo-frame-reloader'
import '../behaviors/update-button'
import '../behaviors/video-time-controls'
import '../behaviors/segment-track'

import '../video/player-highlights-buttons'
import '../video/video-controls'
import '../video/video-ai-chat'

import '../jump-menu'

import '../intercom'
import '../segment-load'
import '../segment'
import '../onboarding/track-channel'

import '../auto-complete-element'
import '../turbo-autocomplete'
import '../turbo-poll'
import '../turbo-frame-missing'
import '../avatar'
import '../billing'
import '../dropbox'
import '../uploader'
import '../retention-policy'
import '../sign-in-renewal-policy'
import '../sentry'

import 'controllers/application'

polyfills.requestIdleCallback.apply()

require('channels')
startActiveStorage()
Rails.start()
