import {observe} from 'selector-observer'

// Validate required fields are filled
observe('.js-validate-form', {
  constructor: HTMLFormElement,
  initialize(form) {
    const validationGroups = form.querySelectorAll('.js-validation')

    form.addEventListener('submit', function (e) {
      for (let i = 0; i < validationGroups.length; ++i) {
        const requiredInput = validationGroups[i].querySelector<HTMLInputElement>('input.js-validated-input')
        const validationNote = validationGroups[i].querySelector('.js-warning-note')

        if (validationGroups[i].classList.contains('js-email-validation')) {
          if (validateEmail(requiredInput.value.trim())) {
            removeMessages(validationNote)
          } else {
            addMessages(validationNote)
            e.preventDefault()
            e.stopImmediatePropagation() // Needed to stop a Turbo form submission
          }
        } else if (validationGroups[i].classList.contains('js-email-domain-validation')) {
          requiredInput.value = requiredInput.value.trim()
          if (requiredInput.value === '' && requiredInput.hasAttribute('allow-blank')) {
            removeMessages(validationNote)
          } else if (validateEmailDomain(requiredInput.value)) {
            removeMessages(validationNote)
          } else {
            addMessages(validationNote)
            e.preventDefault()
          }
        } else {
          if (requiredInput.value === '' && !requiredInput.disabled && !requiredInput.closest('[disabled]')) {
            addMessages(validationNote)
            requiredInput.classList.add('invalid')
            e.preventDefault()
          } else {
            removeMessages(validationNote)
            requiredInput.classList.remove('invalid')
          }
        }
      }

      function removeMessages(message: Element) {
        message.classList.remove('block')
        message.classList.add('none')
        // New CSS version
        message.classList.add('hidden')
      }
      function addMessages(message: Element) {
        message.classList.remove('none')
        message.classList.add('block')
        // New CSS version
        message.classList.remove('hidden')
      }
    })
  },
})

// on blur validate email
observe('.js-email-validation', (validationGroup) => {
  const emailInput = validationGroup.querySelector<HTMLInputElement>('input.js-validated-input')
  const validationNote = validationGroup.querySelector('.js-warning-note')

  emailInput.addEventListener('blur', function () {
    if (emailInput.value === '') {
      validationNote.classList.remove('block')
      validationNote.classList.add('none')
      // New CSS version
      validationNote.classList.add('hidden')
    } else if (validateEmail(emailInput.value.trim())) {
      validationNote.classList.remove('block')
      validationNote.classList.add('none')
      // New CSS version
      validationNote.classList.add('hidden')
    } else {
      validationNote.classList.add('block')
      validationNote.classList.remove('none')
      // New CSS version
      validationNote.classList.remove('hidden')
    }
  })
})

function validateEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

observe('.js-email-domain-validation', (validationGroup) => {
  const emailDomainInput = validationGroup.querySelector<HTMLInputElement>('input.js-validated-input')
  const validationNote = validationGroup.querySelector('.js-warning-note')

  emailDomainInput.addEventListener('blur', function () {
    emailDomainInput.value = emailDomainInput.value.trim()
    if (emailDomainInput.value === '') {
      validationNote.classList.remove('block')
      validationNote.classList.add('none')
      // New CSS version
      validationNote.classList.add('hidden')
    } else if (validateEmailDomain(emailDomainInput.value)) {
      validationNote.classList.remove('block')
      validationNote.classList.add('none')
      // New CSS version
      validationNote.classList.add('hidden')
    } else {
      validationNote.classList.add('block')
      validationNote.classList.remove('none')
      // New CSS version
      validationNote.classList.remove('hidden')
    }
  })
  emailDomainInput.addEventListener('focus', function () {
    validationNote.classList.remove('block')
    validationNote.classList.add('none')
    // New CSS version
    validationNote.classList.add('hidden')
  })
})

function validateEmailDomain(email: string) {
  const re = /^[\p{L}\p{N}-]+(\.[\p{L}\p{N}]{2,}){1,4}$/u
  return re.test(String(email).toLowerCase())
}

// Compare passwords and make sure they're the same...
observe('.js-compare-passwords', {
  constructor: HTMLFormElement,
  initialize(form) {
    const passwordInput = form.querySelector<HTMLInputElement>('input.js-password-input')
    const passwordConfirmInput = form.querySelector<HTMLInputElement>('input.js-confirm-password-input')
    const validationNote = form.querySelector('.js-warning-note')
    const submitButton = form.querySelector<HTMLInputElement>('input[type="submit"]')
    passwordInput.addEventListener('blur', function () {
      if (passwordInput.value !== '' && passwordConfirmInput.value !== '') {
        if (comparePasswords(passwordInput.value, passwordConfirmInput.value)) {
          removeMessage(validationNote)
        } else {
          addMessage(validationNote)
        }
      }
    })
    passwordConfirmInput.addEventListener('blur', function () {
      if (passwordInput.value !== '' && passwordConfirmInput.value !== '') {
        if (comparePasswords(passwordInput.value, passwordConfirmInput.value)) {
          removeMessage(validationNote)
        } else {
          addMessage(validationNote)
        }
      }
    })

    form.addEventListener('submit', function (e) {
      e.preventDefault()
      if (comparePasswords(passwordInput.value, passwordConfirmInput.value)) {
        removeMessage(validationNote)
      } else {
        addMessage(validationNote)
        return false
      }
      form.submit()
    })

    function comparePasswords(password1: string, password2: string) {
      if (password1 !== '' && password2 !== '') {
        if (password1 !== password2) {
          return false
        } else {
          return true
        }
      }
    }
    function removeMessage(message: Element) {
      message.classList.remove('block')
      message.classList.add('none')
      submitButton.disabled = false
      // New CSS version
      message.classList.add('hidden')
    }
    function addMessage(message: Element) {
      message.classList.remove('none')
      message.classList.add('block')
      // New CSS version
      message.classList.remove('hidden')
    }
  },
})
