import {ajax} from '@rails/ujs'
import {on} from 'delegated-events'
import {observe} from 'selector-observer'
import type {default as AutocompleteElement, AutocompleteEvent} from '@scottjg/auto-complete-element'

observe(
  '.js-add-groups-and-members-autocomplete auto-complete, .js-add-groups-and-members-access-autocomplete auto-complete',
  {
    add(el) {
      updateAutocompleteExclusions(el)
    },
  }
)

on(
  'auto-complete-change',
  '.js-add-groups-and-members-autocomplete auto-complete, .js-add-groups-and-members-access-autocomplete auto-complete',
  (event) => {
    const url = event.currentTarget.getAttribute('data-render-tag-url')
    handleMemberCompletion(url, 'sgids', event)
  }
)

on('auto-complete-change', '.js-member-completer', (event) => {
  handleMemberCompletion('/autocomplete/member/tags', 'member_ids', event)
})

on('auto-complete-change', '[data-autocomplete-tag]', (event) => {
  const tagPath = event.currentTarget.getAttribute('data-autocomplete-tag')
  const paramName = event.currentTarget.getAttribute('data-autocomplete-param')
  handleTagCompletion(tagPath, paramName, event)
})

on('tag-added', '[data-autocomplete-tag]', (event) => {
  event.currentTarget.dispatchEvent(new Event('search-filter-added', {bubbles: true}))
})

on('auto-complete-change', '.js-user-completer', (event) => {
  handleTagCompletion('/autocomplete/people/tags', 'user_ids', event)
})

on('auto-complete-change', '.js-retention-policy-collection-completer', (event) => {
  handleTagCompletion('/admin/video_retention_policies/collection_tag', 'video_retention_policy[collection_ids]', event)
})

on('auto-complete-change', '.js-guest-collection-completer', (event) => {
  handleTagCompletion('/autocomplete/guest_collections/tags', 'collection_ids', event)
})

on('auto-complete-change', '.js-collection-completer', (event) => {
  handleTagCompletion('/autocomplete/collections/tags', 'collection_ids', event)
})

on('auto-complete-change', '.js-secret-collection-completer', (event) => {
  handleTagCompletion('/autocomplete/collections/tags', 'secret_collection_ids', event)
})

on('auto-complete-change', '.js-secret-acccess-collection-completer', (event) => {
  handleTagCompletion('/autocomplete/access_collections/tags', 'access_collection_ids', event)
})

on('auto-complete-change', '.js-autosub-collection-completer', (event) => {
  handleTagCompletion('/autocomplete/collections/tags', 'collection_ids', event)
})

on('auto-complete-change', '.js-pinned-collection-completer', (event) => {
  handleTagCompletion('/autocomplete/collection_list/items', 'collection_ids', event)
})

on('change', 'input[data-remove-unchecked]', (e) => {
  const checkbox = e.currentTarget as HTMLInputElement
  if (checkbox.checked) return
  const container = checkbox.closest('[data-remove-unchecked-target]')
  container.querySelector<HTMLElement>('.js-destroy-tag')?.click()
})

on('click', 'button[data-clear-search-filter]', () => {
  for (const input of document.querySelectorAll<HTMLInputElement>('input[data-search-filter]:checked')) {
    input.click()
  }
})

observe('input[data-search-filter]', {
  initialize() {
    const button = document.querySelector<HTMLElement>('button[data-clear-search-filter]')

    function checkFilterPresence() {
      const anyChecked = !!document.querySelector('input[data-search-filter]:checked')
      button.classList.toggle('hidden', !anyChecked)
    }

    return {add: checkFilterPresence, remove: checkFilterPresence}
  },
})

function removeMemberTagCard(tagCard: HTMLElement) {
  const input = tagCard.querySelector('input')
  const destroyedID = input.value
  const container = tagCard.closest('auto-complete')

  tagCard.remove()

  if (!container) return
  updateAutocompleteExclusions(container)
  // only focus after the exclusion has been updated
  if (!container.hasAttribute('data-no-autofocus')) container.querySelector('input')?.focus()
  if (container.querySelectorAll('.js-tag-card').length === 0) {
    const emptyState = container.querySelector('.js-empty-tags')
    if (emptyState) emptyState.classList.remove('none')
    if (emptyState) emptyState.classList.remove('hidden') // New CSS version
  }

  container.dispatchEvent(new CustomEvent('tag-removed'))

  const autosubmitName = container.getAttribute('data-autosubmit-name')
  if (autosubmitName) {
    const autosubmitVal = container.getAttribute('data-autosubmit-value')

    ajax({
      url: '/autocomplete/people/tags',
      type: 'DELETE',
      data: `${encodeURIComponent(autosubmitName)}=${encodeURIComponent(autosubmitVal)}&id=${encodeURIComponent(
        destroyedID
      )}`,
      success() {
        //nothing to do
      },
      error(data) {
        console.error(data)
      },
    })
  }
}

on('change', '.js-collection-member-select', (event) => {
  event.preventDefault()
  const selectedOption = event.currentTarget.querySelector<HTMLOptionElement>('option:checked') as HTMLOptionElement
  if (selectedOption.hasAttribute('data-member-remove')) {
    const tagCard = event.currentTarget.closest('.js-tag-card') as HTMLElement
    removeMemberTagCard(tagCard)
  }
})

on('click', '.js-destroy-tag', (event) => {
  event.preventDefault()
  const tagCard = event.currentTarget.closest('.js-tag-card') as HTMLElement
  removeMemberTagCard(tagCard)
})

export function updateAutocompleteExclusions(container: Element) {
  // update autocomplete url to tell server not to include currently selected items
  const newInputs = container.querySelectorAll<HTMLInputElement>('.js-tag-card input:not(.js-ignore-for-exid)')
  const newIDElements = Array.from(newInputs)
  const [srcURL, oldParams] = container.getAttribute('src').split('?')
  let newParams = newIDElements
    .slice(0, 50) // Need to avoid generating a too-long URL
    .map((el) => {
      const id = el.value
      if (el.classList.contains('exid-user')) {
        return `exUID[]=${encodeURIComponent(id)}`
      } else if (el.classList.contains('exid-group')) {
        return `exGID[]=${encodeURIComponent(id)}`
      } else if (el.classList.contains('exid-email')) {
        return `exEID[]=${encodeURIComponent(id)}`
      } else {
        return `exID[]=${encodeURIComponent(id)}`
      }
    })
    .join('&')

  for (const param of (oldParams || '').split('&').filter((p) => !p.match(/^(exUID|exID|exGID)/))) {
    newParams += '&'
    newParams += param
  }
  container.setAttribute('src', `${srcURL}?${newParams}`)
}

function handleMemberCompletion(url: string, attrName: string, event: CustomEvent) {
  const container = event.currentTarget as AutocompleteElement
  const newID = container.value
  if (newID === '') {
    // ignore the event that it fires when you clear the selection
    return
  }
  const input = (event as AutocompleteEvent).relatedTarget
  input.value = ''
  container.removeAttribute('value')

  //get all the existing ids of tagged users
  const inputs = container.querySelectorAll<HTMLInputElement>(`input[name="${attrName}[]"]`)
  const ids = Array.from(inputs).map((el) => el.value)

  if (ids.includes(newID)) {
    //no duplicates allowed
    return
  }

  //prevent input while we load the new tags view
  input.readOnly = true

  ajax({
    url,
    type: 'GET',
    data: `${encodeURIComponent(attrName)}[]=${encodeURIComponent(newID)}`,
    success(info) {
      input.readOnly = false
      const tags = container.querySelector('.js-tags')
      tags?.prepend(...info.body.childNodes)
      container.querySelector('input')?.focus()
      if (container.querySelectorAll('.js-tag-card').length > 0) {
        const emptyState = container.querySelector('.js-empty-tags')
        if (emptyState) {
          emptyState.classList.add('none')
          // New CSS version
          emptyState.classList.add('hidden')
        }
      }

      updateAutocompleteExclusions(container)
    },
    error(err) {
      input.readOnly = false
      console.log(err)
    },
  })
}

function handleTagCompletion(url: string, attrName: string, event: CustomEvent) {
  const container = event.currentTarget as AutocompleteElement
  const newID = container.value
  if (newID === '') {
    // ignore the event that it fires when you clear the selection
    return
  }
  const input = (event as AutocompleteEvent).relatedTarget
  input.value = ''
  container.removeAttribute('value')

  //get all the existing ids of tagged users
  const inputs = container.querySelectorAll<HTMLInputElement>(`input[name="${attrName}[]"]`)
  const ids = Array.from(inputs).map((el) => el.value)

  if (ids.includes(newID)) {
    //no duplicates allowed
    return
  }

  //prevent input while we load the new tags view
  input.readOnly = true

  let data = ids
    .concat(newID)
    .map((id) => `${attrName}[]=${encodeURIComponent(id)}`)
    .join('&')
  const autosubmitName = container.getAttribute('data-autosubmit-name')
  if (autosubmitName) {
    const autosubmitValue = container.getAttribute('data-autosubmit-value')
    data += `&${encodeURIComponent(autosubmitName)}=${encodeURIComponent(autosubmitValue)}`
  }
  data += `&new_id=${encodeURIComponent(newID)}`
  const videoVisibility = container.getAttribute('data-video-visibility')
  if (videoVisibility) {
    data += `&video_visibility=${encodeURIComponent(videoVisibility)}`
  }

  ajax({
    url,
    type: autosubmitName ? 'POST' : 'GET',
    data,
    success(info) {
      // update tag list
      input.readOnly = false
      container.querySelector('.js-tags').innerHTML = info.body.innerHTML
      updateAutocompleteExclusions(container)
      const emptyState = container.querySelector('.js-empty-tags')
      if (emptyState) emptyState.classList.add('hidden')
      container.dispatchEvent(new CustomEvent('tag-added', {bubbles: true, detail: {el: container}}))
    },
    error(err) {
      input.readOnly = false
      console.log(err)
    },
  })
}
