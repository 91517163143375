let alreadyIdentifiedUser: string | null = null
let alreadyGroupedGroup: string | null = null
let analyticsReady = false

export function getAnalyticsData(type: 'user' | 'channel'): {[key: string]: string} | null {
  const data = document.querySelector<HTMLMetaElement>(`meta[name="rewatch:analytics:${type}"]`)?.content
  return data ? JSON.parse(data) : null
}

document.addEventListener('turbo:load', segmentLoadAndIdentify)

export async function segmentLoadAndIdentify() {
  //wait for segment to load
  if (!analyticsReady) {
    await new Promise((resolve) => window.analytics.ready(resolve))
    window.analytics.timeout(500)
    analyticsReady = true
  }

  const userInfo = getAnalyticsData('user')
  const channelInfo = getAnalyticsData('channel')

  if (userInfo) {
    if (alreadyIdentifiedUser !== userInfo.id) {
      await new Promise((resolve) =>
        window.analytics.identify(
          userInfo.id,
          {
            name: userInfo.name,
            email: userInfo.email,
            channelRole: userInfo.channelRole,
            channelName: channelInfo?.name,
            channelId: channelInfo?.id,
            createdAt: userInfo.createdAt,
            company: {
              id: channelInfo?.id,
              name: channelInfo?.name,
            },
          },
          {
            integrations: {
              Intercom: {
                // eslint-disable-next-line camelcase
                user_hash: userInfo.intercomHMAC,
              },
            },
          },
          resolve
        )
      )

      alreadyIdentifiedUser = userInfo.id
      if (channelInfo) {
        if (alreadyIdentifiedUser && alreadyGroupedGroup !== channelInfo.id) {
          await new Promise((resolve) => {
            window.analytics.group(
              channelInfo.id,
              {
                createdAt: channelInfo.createdAt,
                name: channelInfo.name,
                company: {
                  id: channelInfo?.id,
                  name: channelInfo?.name,
                },
                plan: channelInfo.plan,
                users: channelInfo.seats,
                // videos:
                // collections:
                website: channelInfo.website,
              },
              {},
              resolve
            )
          })
          alreadyGroupedGroup = channelInfo.id
        }
      }
    }
  } else if (window.location.pathname === '/login') {
    window.analytics.reset()
  }
  segmentReportFilteredPage()
}

function segmentReportFilteredPage() {
  let docTitle = document.title
  let docPath = document.location.pathname
  let docURL = document.URL
  if (
    docPath.match(/^\/share\/video\//) ||
    docPath.match(/^\/video\/analytics\//) ||
    docPath.match(/^\/video\/[0-9]+\//) ||
    docPath.match(/^\/video\/embed\//) ||
    docPath.match(/^\/video\/chat_embed\//) ||
    docPath.match(/^\/collection\/[0-9]+\//)
  ) {
    docTitle = docTitle.replace(/^.*· ?/, '')
    docPath = docPath.replace(/\/[^/]+\/?$/, '') //remove slug or key from path
    docURL = docURL.replace(/\/[^/]+\/?$/, '') //remove slug or key from URL
  }

  window.analytics.page({
    path: docPath,
    search: document.location.search,
    title: docTitle,
    url: docURL,
  })
}
