import {on} from 'delegated-events'

function markTooltipShowing(event: Event) {
  const target = event.target as Element
  target.classList.remove('hide-tooltip')
  target.classList.add('tooltip-shown')
}

function unmarkTooltipShowing(event: Event) {
  const target = event.target as Element
  if (target.matches(':hover, :focus')) return
  target.classList.remove('hide-tooltip', 'tooltip-shown')
}

on('mouseenter', '.tooltipped', markTooltipShowing, {capture: true})
on('focus', '.tooltipped', markTooltipShowing, {capture: true})

on('mouseleave', '.tooltipped', unmarkTooltipShowing, {capture: true})
on('blur', '.tooltipped', unmarkTooltipShowing, {capture: true})

document.addEventListener(
  'keydown',
  function (event) {
    if (event.key !== 'Escape') return

    for (const tooltip of document.querySelectorAll('.tooltipped.tooltip-shown')) {
      tooltip.classList.add('hide-tooltip')
      tooltip.classList.remove('tooltip-shown')
      event.stopPropagation()
    }
  },
  {capture: true}
)
