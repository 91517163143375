import {observe} from 'selector-observer'

export function ariaNotify(text: string) {
  const liveRegion = document.getElementById('aria-notify')
  if (!liveRegion) {
    console.warn('Live region not found.')
  }
  liveRegion.textContent = text
  setTimeout(() => {
    liveRegion.textContent = ''
  }, 3000)
}

observe('[data-aria-notify]', (el) => {
  ariaNotify(el.getAttribute('data-aria-notify') || el.textContent)
})
