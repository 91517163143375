import Combobox from '@github/combobox-nav'

class RewatchJumpMenu extends HTMLElement {
  menu: HTMLElement
  input: HTMLInputElement
  optionsList: HTMLElement
  recentVisitList: HTMLElement
  recentSearchList: HTMLElement
  previouslyActiveEl: Element
  launchButton: HTMLAnchorElement
  closeButton: HTMLButtonElement
  combobox: Combobox

  constructor() {
    super()
  }

  connectedCallback() {
    this.input = this.querySelector('[data-jump-input]')
    this.optionsList = this.querySelector('[data-jump-options-list]')
    this.recentVisitList = this.querySelector('[data-jump-recent-visits]')
    this.recentSearchList = this.querySelector('[data-jump-recent-searches]')
    this.launchButton = document.querySelector('[data-jump-menu-launch]')
    this.closeButton = this.querySelector('[data-jump-menu-close]')
    this.combobox = new Combobox(this.input, this.optionsList)

    this.onCommit = this.onCommit.bind(this)
    this.onInput = this.onInput.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this)
    this.onClick = this.onClick.bind(this)
    this.close = this.close.bind(this)

    this.optionsList.addEventListener('combobox-commit', this.onCommit)
    this.input.addEventListener('input', this.onInput)
    document.addEventListener('keydown', this.onKeyDown)
    document.addEventListener('click', this.onClick)
    const globalSearchInput = document.querySelector<HTMLInputElement>('#ref-search-field')
    if (globalSearchInput) {
      globalSearchInput.focus()
      // Moves cursor to the end of the text
      globalSearchInput.value = ''
      globalSearchInput.value = new URLSearchParams(window.location.search).get('q')
    }
  }

  disconnectedCallback() {
    this.optionsList.removeEventListener('combobox-commit', this.onCommit)
    this.input.removeEventListener('input', this.onInput)
    document.removeEventListener('keydown', this.onKeyDown)
    document.removeEventListener('click', this.onClick)
    document.removeEventListener('turbo:before-cache', this.close)
  }

  onCommit({target}: Pick<Event, 'target'>) {
    const selectedOption = target as HTMLAnchorElement
    const globalSearchInput = document.querySelector<HTMLInputElement>('#ref-search-field')
    if (globalSearchInput && selectedOption) {
      const selectedURL = new URL(selectedOption.href)
      globalSearchInput.focus()
      globalSearchInput.value = ''
      globalSearchInput.value = selectedURL.searchParams.get('q')
    }

    this.close()
  }

  onInput() {
    const dynamicOptions = this.querySelector<HTMLElement>('[data-jump-dynamic-options]')
    const queryPresent = this.input.value !== ''
    const searchPath = queryPresent
      ? `/search/videos?q=${encodeURIComponent(this.input.value)}&sort=default`
      : '/search/videos'

    dynamicOptions.classList.toggle('hidden', !queryPresent)
    this.recentSearchList.classList.toggle('hidden', queryPresent || this.recentSearchList.childElementCount < 3)
    this.recentVisitList.classList.toggle('hidden', queryPresent || this.recentVisitList.childElementCount < 3)

    requestAnimationFrame(() => {
      renderSearchOption(queryPresent, searchPath, this.input.value)
    })
  }

  onKeyDown(event: KeyboardEvent) {
    const hitCmdJ = event.metaKey && !event.altKey && event.code === 'KeyJ'
    const hitCtrlJ = event.ctrlKey && !event.altKey && event.code === 'KeyJ'
    const hitEscWhileActive = this.isActive && event.code === 'Escape'
    if (hitCmdJ || hitCtrlJ || hitEscWhileActive) {
      event.preventDefault()
      this.toggle()
    }
  }

  onClick(event: MouseEvent) {
    if (event.target === this.closeButton) {
      event.stopPropagation()
      this.toggle()
    } else if (event.target === this.launchButton) {
      if (!event.metaKey) {
        event.stopPropagation()
        event.preventDefault()
        this.toggle()
      }
    }
  }

  toggle() {
    this.isActive ? this.close() : this.open()
  }

  close() {
    this.setAttribute('hidden', 'true')
    this.setAttribute('aria-expanded', 'false')
    this.removeAttribute('data-ready-to-animate')
    this.combobox.stop()
    document.removeEventListener('turbo:before-cache', this.close)
    if (this.previouslyActiveEl instanceof HTMLElement) this.previouslyActiveEl.focus()
  }

  open() {
    this.previouslyActiveEl = document.activeElement
    this.removeAttribute('hidden')
    this.setAttribute('aria-expanded', 'true')
    this.combobox.start()
    this.input.focus()
    document.addEventListener('turbo:before-cache', this.close)
    setTimeout(() => {
      this.setAttribute('data-ready-to-animate', '')
    }, 1)

    this.renderRecentOptions()
  }

  renderRecentOptions() {
    if (this.optionsList.querySelector('[role=option]:not(.hidden)')) return
    const recentVisits = JSON.parse(localStorage.getItem(`${window.location.hostname}/recent-visits`)) || []
    const recentSearches = JSON.parse(localStorage.getItem(`${window.location.hostname}/recent-searches`)) || []

    for (const visit of recentVisits.concat(recentSearches)) {
      if (visit.type === 'search' && visit.path === window.location.pathname + window.location.search) continue
      if (visit.path === window.location.pathname) continue
      const destination = visit.type === 'search' ? this.recentSearchList : this.recentVisitList
      renderRecentOption(destination, visit.path, visit.title, visit.type)
    }

    this.recentSearchList.classList.toggle('hidden', recentSearches.length < 2)
    this.recentVisitList.classList.toggle('hidden', recentVisits.length < 2)
  }

  get isActive() {
    return this.getAttribute('aria-expanded') === 'true' && !this.hasAttribute('hidden')
  }
}

function renderSearchOption(isSelected: boolean, path: string, label: string) {
  const searchOption = document.querySelector<HTMLAnchorElement>('#jump-search-option')
  const newSearchOption = searchOption.cloneNode(true) as HTMLAnchorElement
  const labelEl = newSearchOption.querySelector<HTMLElement>('[data-jump-option-label]')

  labelEl.textContent = label
  newSearchOption.href = path
  newSearchOption.setAttribute('aria-selected', isSelected.toString())
  newSearchOption.classList.toggle('hidden', !isSelected)

  searchOption.replaceWith(newSearchOption)
}

function renderRecentOption(destination: HTMLElement, path: string, title: string, type: string) {
  const optionTemplate = document.querySelector<HTMLTemplateElement>('[data-jump-option-tpl]')
  const optionClone = optionTemplate.content.cloneNode(true) as DocumentFragment
  const option = optionClone.querySelector<HTMLAnchorElement>('[role=option]')
  const optionTitle = option.querySelector<HTMLElement>('[data-jump-option-label]')
  const optionIcon = option.querySelector<HTMLElement>('[data-jump-option-icon]')
  const optionIconTemplate = document.querySelector<HTMLTemplateElement>(`[data-jump-option-icon='${type}']`)

  option.href = path
  optionTitle.textContent = title
  if (optionIconTemplate) optionIcon.replaceWith(optionIconTemplate.content.cloneNode(true))

  destination.append(option)
}

if (!window.customElements.get('rewatch-jump-menu')) {
  window.RewatchJumpMenu = RewatchJumpMenu
  window.customElements.define('rewatch-jump-menu', RewatchJumpMenu)
}

declare global {
  interface Window {
    RewatchJumpMenu: typeof RewatchJumpMenu
  }
  interface HTMLElementTagNameMap {
    'rewatch-jump-menu': RewatchJumpMenu
  }
}
