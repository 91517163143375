import {on} from 'delegated-events'

function openModal(e: Event & {currentTarget: Element}) {
  const target = e.target as HTMLElement
  // test if target prevents modal from opening (helpful for external links)
  if (target.classList?.contains('js-prevent-modal-open')) return

  const currentTarget = e.currentTarget
  const modal = document.getElementById(
    currentTarget.closest('[data-modal-id]').getAttribute('data-modal-id')
  ) as HTMLDetailsElement
  modal.querySelector('summary').click()
}

on('click', '.js-open-modal', openModal)

on('change', '.js-open-modal', openModal)
