import {observe} from 'selector-observer'
import {getAnalyticsData} from '../segment-load'

observe('.js-track-new-channel', {
  constructor: HTMLFormElement,
  add(form) {
    form.addEventListener('submit', (e) => {
      e.preventDefault()
      setTimeout(() => {
        // in case segment is adblocked or doesn't load for some reason,
        // we give up after a second and just submit the form
        if (!form.submitted) {
          form.submitted = true
          form.submit()
        }
      }, 1000)
      window.analytics.track(
        'Channel signed up',
        {
          channelName: form.querySelector<HTMLInputElement>('[name="channel[name]"]').value,
          userId: getAnalyticsData('user')?.id,
        },
        {
          integrations: {
            All: false,
            'Google Adwords New': true,
            Amplitude: true,
          },
        },
        () => {
          // wait for the segment callback to actually submit the form.
          // it should be safe as segment enforces a 300ms timeout here
          if (form.submitted) {
            form.submitted = true
            form.submit()
          }
        }
      )
    })
  },
})
