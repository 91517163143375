import {observe} from 'selector-observer'
import parseJSON from 'date-fns/parseJSON'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import differenceInHours from 'date-fns/differenceInHours'
import differenceInDays from 'date-fns/differenceInDays'
import differenceInYears from 'date-fns/differenceInYears'

// @ts-expect-error: https://github.com/josh/selector-observer/pull/19#issuecomment-481361246
observe('.js-local-date', async (el) => {
  const {default: format} = await import('date-fns/format')
  const dateFormat = el.getAttribute('data-date-format') || 'PP p'
  el.textContent = format(parseJSON(el.textContent), dateFormat)
  el.classList.remove('js-local-date')
})

// @ts-expect-error: https://github.com/josh/selector-observer/pull/19#issuecomment-481361246
observe('.js-local-date-short', async (el) => {
  const {default: format} = await import('date-fns/format')
  el.textContent = format(parseJSON(el.textContent), 'P')
  el.classList.remove('js-local-date')
})

// @ts-expect-error: https://github.com/josh/selector-observer/pull/19#issuecomment-481361246
observe('.js-posted-datetime', async (el) => {
  const {default: format} = await import('date-fns/format')
  const now = new Date()
  const commentDateTime = parseJSON(el.getAttribute('data-datetime'))
  const minuteDiff = differenceInMinutes(now, commentDateTime)
  const hourDiff = differenceInHours(now, commentDateTime)
  const dayDiff = differenceInDays(now, commentDateTime)
  const yearDiff = differenceInYears(now, commentDateTime)
  let formattedDate = ''

  if (yearDiff >= 1) {
    formattedDate = `${format(commentDateTime, 'MMM do, yyyy')}`
  } else if (dayDiff >= 2) {
    formattedDate = `${format(commentDateTime, 'MMM do')}`
  } else if (dayDiff >= 1 && dayDiff < 2) {
    formattedDate = '1 day ago'
  } else if (minuteDiff < 1) {
    formattedDate = 'Just now'
  } else if (hourDiff < 1) {
    formattedDate = `${minuteDiff}min ago`
  } else {
    formattedDate = `${hourDiff}hr ago`
  }

  el.innerHTML = `${formattedDate}`
})

// @ts-expect-error: https://github.com/josh/selector-observer/pull/19#issuecomment-481361246
observe('.js-posted-timestamp', async (el) => {
  const {default: format} = await import('date-fns/format')
  const commentDateTime = parseJSON(el.getAttribute('data-datetime'))
  el.innerHTML = format(commentDateTime, 'MM/dd/yy HH:mm')
})

// @ts-expect-error: https://github.com/josh/selector-observer/pull/19#issuecomment-481361246
observe('.js-local-time-of-day', async (el) => {
  const {default: format} = await import('date-fns/format')
  const dateTime = parseJSON(el.getAttribute('data-datetime'))
  el.innerHTML = format(dateTime, 'h:mmaaa')
})
