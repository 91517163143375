import {ajax} from '@rails/ujs'
import {on} from 'delegated-events'
import {observe} from 'selector-observer'
import {reportStats} from 'stats'
import {secondsToHms} from 'time'

interface Hls {
  loadSource(url: string): void
  attachMedia(element: HTMLVideoElement): void
  startLoad(): void
  destroy(): void
}

declare global {
  interface Window {
    Hls: {
      new (arg0: {autoStartLoad: boolean}): Hls
      isSupported(): boolean
    }
  }
}

observe('[data-load-hls]', {
  constructor: HTMLVideoElement,
  initialize(video) {
    const videoStart = parseFloat(video.getAttribute('data-start-ts'))
    const videoEnd = parseFloat(video.getAttribute('data-end-ts'))
    const captionsEnabled = video.getAttribute('data-cc') === 'true'

    // Attach listeners
    function handleTimeUpdate() {
      if (video.currentTime >= videoEnd) video.pause()
      if (video.currentTime < videoStart) video.pause()
    }

    function handlePlay() {
      // if playing from the end of the video, restart at the beginning
      if (video.currentTime >= videoEnd) video.currentTime = videoStart
    }

    function handleLoadedMetadata() {
      for (const track of video.textTracks) {
        track.mode = track.kind === 'captions' && captionsEnabled ? 'showing' : 'disabled'
      }
    }

    video.addEventListener('loadedmetadata', handleLoadedMetadata)
    video.addEventListener('timeupdate', handleTimeUpdate)
    video.addEventListener('play', handlePlay)

    // initialize HLS
    // good docs: https://github.com/video-dev/hls.js/blob/master/docs/API.md

    let hls: Hls
    if (window.Hls.isSupported()) {
      hls = new window.Hls({autoStartLoad: video.getAttribute('preload') === 'auto'})
      hls.loadSource(video.getAttribute('data-load-hls'))
      hls.attachMedia(video)
      video.addEventListener('play', () => hls.startLoad())

      if (videoStart) video.currentTime = videoStart
    } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
      video.setAttribute('src', video.getAttribute('data-load-hls'))
    } else {
      console.error('unsupported browser')
    }

    return {
      remove() {
        hls.destroy()
      },
    }
  },
})

observe('[data-report-video-stats]', {
  constructor: HTMLVideoElement,
  initialize(video) {
    let progressTimer: NodeJS.Timeout = null
    const statTypes = video.getAttribute('data-report-video-stats')

    function handleProgressUpdate() {
      reportStats({
        event: 'progressUpdate',
        currentTime: video.currentTime,
      })
    }

    function handleWatchedHighlight() {
      reportStats({currentTime: video.currentTime, event: 'watchedHighlight'})
    }

    function handlePlay() {
      progressTimer = setInterval(handleProgressUpdate, 3000)
      reportStats({currentTime: video.currentTime, event: 'play'})
    }

    function handlePause() {
      if (progressTimer) clearTimeout(progressTimer)
    }

    return {
      add() {
        video.addEventListener('pause', handlePause)

        for (const statType of statTypes.split(' ')) {
          if (statType === 'watchedHighlight') video.addEventListener('play', handleWatchedHighlight)
          else if (statType === 'play') video.addEventListener('play', handlePlay)
        }
      },
      remove() {
        video.addEventListener('pause', handlePause)
        if (progressTimer) clearTimeout(progressTimer)

        for (const statType of statTypes.split(' ')) {
          if (statType === 'watchedHighlight') video.removeEventListener('play', handleWatchedHighlight)
          else if (statType === 'play') video.removeEventListener('play', handlePlay)
        }
      },
    }
  },
})

observe('.js-video-controls-time', (timeEl) => {
  const video = document.getElementById(timeEl.getAttribute('data-video-id')) as HTMLVideoElement
  const videoStart = parseFloat(video.getAttribute('data-start-ts'))
  video.addEventListener('timeupdate', () => {
    if (video.getAttribute('data-scrubbing') === 'true') return
    timeEl.textContent = secondsToHms(video.currentTime - videoStart)
  })
})

observe('.js-video-controls-progress', {
  constructor: HTMLDivElement,
  initialize(progressEl) {
    const video = document.getElementById(progressEl.getAttribute('data-video-id')) as HTMLVideoElement
    const videoStart = parseFloat(video.getAttribute('data-start-ts'))
    const videoEnd = parseFloat(video.getAttribute('data-end-ts'))
    const duration = videoEnd - videoStart
    const progressBar = progressEl.querySelector('#ref-progress-bar') as HTMLElement
    const playhead = progressEl.querySelector('#ref-playhead') as HTMLElement
    const timeEl = document.querySelector('.js-video-controls-time')
    video.addEventListener('timeupdate', () => {
      if (isScrubbing) return
      const adjustedTime = video.currentTime - videoStart
      const clampedPercent = Math.min(Math.max(adjustedTime / duration, 0), 1)
      progressBar.style.width = `${clampedPercent * 100}%`
      playhead.style.left = `${clampedPercent * 100}%`
    })

    let isScrubbing = false
    let newPercent = 0

    function dragPlayhead(e: MouseEvent) {
      newPercent = (e.clientX - progressEl.getBoundingClientRect().left) / progressEl.clientWidth
      const clampedPercent = Math.min(Math.max(newPercent, 0), 1)
      progressBar.style.width = `${clampedPercent * 100}%`
      playhead.style.left = `${clampedPercent * 100}%`

      timeEl.textContent = secondsToHms(videoStart + duration * newPercent)
    }

    function handleMousedown(e: MouseEvent) {
      e.preventDefault() // prevent text selection
      video.setAttribute('data-scrubbing', 'true')
      isScrubbing = true
      dragPlayhead(e)
    }

    function handleMouseup() {
      if (!isScrubbing) return
      video.setAttribute('data-scrubbing', 'false')
      isScrubbing = false
      video.currentTime = videoStart + duration * newPercent
    }

    function handleMousemove(e: MouseEvent) {
      if (!isScrubbing) return
      dragPlayhead(e)
    }

    progressEl.addEventListener('mousedown', handleMousedown)
    document.addEventListener('mousemove', handleMousemove)
    document.addEventListener('mouseup', handleMouseup)
    return {
      remove() {
        document.removeEventListener('mousemove', handleMousemove)
        document.removeEventListener('mouseup', handleMouseup)
      },
    }
  },
})

observe('.js-video-controls-play-pause', {
  constructor: HTMLElement,
  initialize(el) {
    const video = document.getElementById(el.getAttribute('data-video-id')) as HTMLVideoElement
    const playIcon = el.querySelector('.js-play-icon')
    const pauseIcon = el.querySelector('.js-pause-icon')

    function updatePlayUi() {
      if (video.paused) {
        playIcon?.classList?.remove('hidden')
        pauseIcon?.classList?.add('hidden')
      } else {
        playIcon?.classList?.add('hidden')
        pauseIcon?.classList?.remove('hidden')
      }
    }

    updatePlayUi()

    video.addEventListener('play', updatePlayUi)
    video.addEventListener('pause', updatePlayUi)

    el.addEventListener('click', async () => {
      if (video.paused) {
        await video.play()
      } else {
        video.pause()
      }

      updatePlayUi()
    })
  },
})

observe('.js-video-controls-sound', {
  constructor: HTMLButtonElement,
  initialize(el) {
    const video = document.getElementById(el.getAttribute('data-video-id')) as HTMLVideoElement
    const muteIcon = el.querySelector('.js-mute-icon')
    const unMuteIcon = el.querySelector('.js-unmute-icon')

    function updateSoundUI() {
      if (video.muted) {
        muteIcon.classList.remove('hidden')
        unMuteIcon.classList.add('hidden')
      } else {
        muteIcon.classList.add('hidden')
        unMuteIcon.classList.remove('hidden')
      }
    }

    updateSoundUI()

    video.addEventListener('volumechange', () => updateSoundUI())
    el.addEventListener('click', () => {
      video.muted = !video.muted
      syncPlayerPrefs({muted: video.muted})
    })
  },
})

observe('.js-video-controls-skip', {
  constructor: HTMLButtonElement,
  initialize(el) {
    const video = document.getElementById(el.getAttribute('data-video-id')) as HTMLVideoElement
    const videoStart = parseFloat(video.getAttribute('data-start-ts'))
    const videoEnd = parseFloat(video.getAttribute('data-end-ts'))
    const interval = parseFloat(el.getAttribute('data-interval'))

    el.addEventListener('click', () => {
      const newTime = video.currentTime + interval
      if (newTime >= videoEnd) {
        video.currentTime = videoEnd
        video.pause()
        return
      } else if (newTime <= videoStart) {
        video.currentTime = videoStart
      } else {
        video.currentTime = newTime
      }
    })
  },
})

const PLAYBACK_RATES = [0.5, 1, 1.25, 1.5, 1.75, 2, 2.5]

observe('video[data-playback-rate]', {
  constructor: HTMLVideoElement,
  initialize(video) {
    video.addEventListener('loadeddata', () => {
      const playbackRate = parseFloat(video.getAttribute('data-playback-rate'))
      if (PLAYBACK_RATES.indexOf(playbackRate) > -1) {
        video['playbackRate'] = playbackRate
      }
    })
  },
})

on('click', '[data-update-playback-rate]', (e) => {
  const rateEl = e.currentTarget as HTMLElement
  const menu = rateEl.closest('[data-playback-rate-menu]')
  const video = document.getElementById(menu.getAttribute('data-video-id')) as HTMLVideoElement
  const newRate = parseFloat(rateEl.getAttribute('data-update-playback-rate'))
  const rateText = menu.querySelector('[data-playback-rate-text]')

  // Set video playback rate
  video['playbackRate'] = newRate
  rateText.textContent = `${newRate}x`

  if (menu.hasAttribute('data-save-playback-rate')) syncPlayerPrefs({playback: newRate})
})

on('click', '.js-video-controls-cc', (e) => {
  const el = e.currentTarget as HTMLElement
  const video = document.getElementById(el.getAttribute('data-video-id')) as HTMLVideoElement
  const language = el.getAttribute('data-lang')
  let enabled = false
  for (const track of video.textTracks) {
    if (track.language === language && track.label !== 'Transcript') {
      enabled = track.mode === 'showing'
      track.mode = enabled ? 'disabled' : 'showing'
      el.setAttribute('aria-pressed', String(!enabled))
    }
  }

  syncPlayerPrefs({cc: !enabled ? language : ''})
})

interface PlayerSettings {
  muted?: boolean
  cc?: string
  playback?: number
}
function syncPlayerPrefs(prefs: PlayerSettings) {
  const formData = new FormData()
  for (const k in prefs) {
    formData.append(k, String(prefs[k as keyof PlayerSettings]))
  }
  ajax({
    url: '/user/player_settings',
    type: 'PATCH',
    data: formData,
    success() {
      // success!
    },
    error(error) {
      console.error(error)
    },
  })
}

observe('[data-reaction-cluster-s]', {
  initialize(el) {
    const video = document.getElementById(el.getAttribute('data-video-id')) as HTMLVideoElement
    const clusterDuration = Number(el.getAttribute('data-reaction-cluster-s'))
    const indexes = [...Array(clusterDuration).keys()]
    let lastRange = ''

    function updateTimeToHighlight() {
      const timestamp = Math.floor(video.currentTime) // 15
      const range = indexes.map((t) => Math.floor(timestamp / clusterDuration) * clusterDuration + t).join(' ')

      if (video.paused) {
        el.removeAttribute('data-video-time')
        return
      }
      if (lastRange === range) return
      el.setAttribute('data-video-time', range)
      lastRange = range
    }

    video.addEventListener('play', updateTimeToHighlight)
    video.addEventListener('timeupdate', updateTimeToHighlight)
  },
})

on('click', '[data-play-at-time]', (event) => {
  const button = event.currentTarget as HTMLButtonElement
  const video = document.getElementById(button.getAttribute('data-video-id')) as HTMLVideoElement
  const videoPlayer = video.closest('rewatch-video')
  const time = Number(button.getAttribute('data-play-at-time'))

  if (videoPlayer) {
    videoPlayer.playAtTime(time, false)
  } else {
    video.currentTime = time
  }
})

on('click', '[data-expand-reaction-id]', (event) => {
  const id = event.currentTarget.getAttribute('data-expand-reaction-id')
  const link = document.querySelector<HTMLAnchorElement>('[data-expand-transcript]')

  if (link) {
    const url = new URL(link.href, window.origin)
    url.searchParams.set('reaction_id', id)
    link.href = url.toString()
    link.click()
  } else {
    const details = document.querySelector<HTMLDetailsElement>(`[data-transcript-reaction-id~='${id}']`)
    const reaction = document.querySelector<HTMLButtonElement>(`[data-reaction-id='${id}']`)

    for (const previousReaction of document.querySelectorAll<HTMLDetailsElement>('[data-reaction-id][aria-current]')) {
      previousReaction.removeAttribute('aria-current')
    }

    for (const openDetails of document.querySelectorAll<HTMLDetailsElement>(
      'details[data-transcript-reaction-id][open]'
    )) {
      if (details === openDetails) continue
      openDetails.open = false
    }
    if (!reaction) return

    details.open = true
    requestAnimationFrame(() => {
      reaction.setAttribute('aria-current', 'location')
      reaction.focus()
      const target = details.closest('[data-transcript-section]') || details
      target.scrollIntoView()
    })
  }
})

observe('[data-check-if-last]', (el) => {
  const list = el.closest('[data-reactions-list]')
  const isLast = list.querySelectorAll('[data-reaction-row]').length === 1
  if (!isLast) return

  list.closest('[data-reactions-cluster]').remove()
})
