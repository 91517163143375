import {on} from 'delegated-events'
import {observe} from 'selector-observer'

on('click', '.js-dbx-chooser', async (event) => {
  const chooser = event.currentTarget
  const options: Dropbox.ChooserOptions = {
    success: (files) => {
      const form = chooser.closest('form')
      const field = form.querySelector<HTMLInputElement>('input.js-upload-info')
      field.value = JSON.stringify(files)
      form.submit()
    },
    linkType: 'direct',
    multiselect: true,
    extensions: ['video'],
    folderselect: false,
  }
  await loadDropbox()
  window.Dropbox.choose(options)
})

observe('.js-dbx-chooser', (chooser) => {
  chooser.addEventListener('mouseenter', loadDropbox, {once: true})
})

let promiseDropbox: null | Promise<void> = null

async function loadDropbox(): Promise<void> {
  if (promiseDropbox) return promiseDropbox
  promiseDropbox = new Promise((resolve) => {
    createDropboxScript().addEventListener('load', () => {
      resolve()
    })
  })
}

function createDropboxScript() {
  const script = document.createElement('script')
  script.src = 'https://www.dropbox.com/static/api/2/dropins.js'
  script.type = 'text/javascript'
  script.id = 'dropboxjs'
  script.setAttribute('data-app-key', 'tk4c0tgrghbppqf')
  document.head.after(script)
  return script
}
