import {ajax} from '@rails/ujs'
import {observe} from 'selector-observer'
import {flashMessage} from './flash-message'

// https://github.com/DefinitelyTyped/DefinitelyTyped/blob/8c078dbfeac0c3c9de93216a0544b54713e3d7d7/types/sortablejs/index.d.ts#L122
interface SortableEvent {
  newIndex: number
  oldIndex: number
  item: Element
  target: Element
}

const initialized = new Set<Element>()

observe('.js-sortable-grid', {
  constructor: HTMLElement,
  initialize(el) {
    el.addEventListener('mouseenter', init, {capture: true})

    async function init(enterEvent: Event) {
      const target = enterEvent.target as Element
      if (!target.closest('.js-sortable-handle')) return
      if (initialized.has(el)) {
        el.removeEventListener('mouseenter', init, {capture: true})
        return
      } else {
        initialized.add(el)
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore https://github.com/SortableJS/Sortable/issues/1693
      const {Sortable} = await import('sortablejs')
      const sortable = Sortable.create(el, {
        animation: 150,
        supportPointer: false,
        draggable: '.js-sortable-item',
        handle: '.js-sortable-handle',
        onEnd: (event: SortableEvent) => {
          if (event.newIndex === event.oldIndex) {
            return
          }
          const paramName = el.getAttribute('data-sortable-param-name') || 'video_id'
          const id = event.item.getAttribute('data-id')
          let videoIDToInsertAfter = -1
          if (event.newIndex > 0) {
            videoIDToInsertAfter = Number(sortable.toArray()[event.newIndex - 1])
          }
          sortable.option('disabled', true)
          ajax({
            url: event.target.getAttribute('data-update-url'),
            type: 'POST',
            data: `${paramName}=${encodeURIComponent(id)}&insert_after=${encodeURIComponent(videoIDToInsertAfter)}`,
            success() {
              sortable.option('disabled', false)

              const flashMessageSuccess = el.getAttribute('data-sortable-flash-message-success')
              if (flashMessageSuccess) flashMessage(flashMessageSuccess, 'success')
            },
            error(data) {
              sortable.option('disabled', false)

              const flashMessageError = el.getAttribute('data-sortable-flash-message-error')
              if (flashMessageError) flashMessage(flashMessageError, 'error')
              console.error(data)
            },
          })
        },
      })
    }
  },
})

observe('.js-sortable-list', {
  constructor: HTMLElement,
  initialize(el) {
    el.addEventListener('mouseenter', init, {capture: true})

    async function init(event: Event) {
      const target = event.target as Element
      if (!target.closest('.js-sortable-item')) return
      if (initialized.has(el)) {
        el.removeEventListener('mouseenter', init, {capture: true})
      } else {
        initialized.add(el)
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore https://github.com/SortableJS/Sortable/issues/1693
      const {Sortable} = await import('sortablejs')
      Sortable.create(el, {
        animation: 150,
        supportPointer: false,
        draggable: '.js-sortable-item',
      })
    }
  },
})
