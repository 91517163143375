import {on} from 'delegated-events'
import {flashMessage} from './flash-message'

on('clipboard-copy', 'clipboard-copy', () => {
  flashMessage('Copied to clipboard', 'success')
})

on('click', '[data-copy-rich-text]', (event: MouseEvent) => {
  const button = event.currentTarget as HTMLButtonElement
  const copyInputId = button.getAttribute('data-copy-input-id')
  const copyHtmlId = button.getAttribute('data-copy-html-id')
  let richText = ''
  let plainText = ''
  if (copyInputId) {
    const richTextInput = document.getElementById(copyInputId) as HTMLInputElement
    richText = richTextInput.value
    plainText = richText
  } else if (copyHtmlId) {
    const el = document.getElementById(copyHtmlId) as HTMLElement
    richText = el.outerHTML
    plainText = el.outerText
      .trim()
      .split('\n')
      .map((line) => line.trim())
      .join('\n')
  } else {
    richText = button.getAttribute('data-copy-rich-text').trim()
    plainText = richText
  }
  const handler = (e: ClipboardEvent) => {
    e.clipboardData.setData('text/plain', plainText)
    e.clipboardData.setData('text/html', richText)
    e.preventDefault()
  }
  document.addEventListener('copy', handler)
  //need to select something temporarily for the clipboard copy to work in Safari
  window.getSelection().selectAllChildren(button)
  document.execCommand('copy')
  //now clear it
  window.getSelection().removeAllRanges()
  document.removeEventListener('copy', handler)

  if (button.hasAttribute('data-copy-external-button')) {
    button.setAttribute('data-copy-button-pressed', 'true')
    setTimeout(function () {
      button.removeAttribute('data-copy-button-pressed')
    }, 2000)
  } else {
    const message = button.hasAttribute('data-copy-confirm-message')
      ? button.getAttribute('data-copy-confirm-message')
      : 'Copied to clipboard'
    flashMessage(message, 'success')
  }
})
