import {on} from 'delegated-events'

on('change', '.js-checkable-item', (event) => {
  const container = event.currentTarget.closest('.js-bulk-actions-container')
  const bulkActions = container.querySelector('.js-toggle-bulk-actions')
  const normalActions = container.querySelector('.js-toggle-normal-actions')
  const selectAllCheckbox = container.querySelector<HTMLInputElement>('input.js-bulk-select-all')
  const allCheckableItems = container.querySelectorAll<HTMLInputElement>('input.js-checkable-item')
  const header = container.querySelector('.js-bulk-actions-header')
  let numChecks = 0
  for (const checkbox of allCheckableItems) {
    if (checkbox.checked) numChecks++
  }
  selectAllCheckbox.checked = numChecks === allCheckableItems.length
  selectAllCheckbox.indeterminate = numChecks > 0 && numChecks < allCheckableItems.length
  for (const count of container.querySelectorAll('.js-bulk-count')) count.textContent = `${numChecks}`
  if (numChecks === 0) {
    bulkActions.classList.add('none')
    normalActions.classList.remove('none')
    // New CSS version
    bulkActions.classList.add('hidden')
    normalActions.classList.remove('hidden')
    header.classList.remove('sticky')
  } else {
    bulkActions.classList.remove('none')
    normalActions.classList.add('none')
    // New CSS version
    bulkActions.classList.remove('hidden')
    normalActions.classList.add('hidden')
    header.classList.add('sticky')
  }
})

on('change', '.js-bulk-select-all', (event) => {
  const input = event.currentTarget as HTMLInputElement
  const container = input.closest('.js-bulk-actions-container')
  const bulkActions = container.querySelector('.js-toggle-bulk-actions')
  const normalActions = container.querySelector('.js-toggle-normal-actions')
  const allCheckableItems = container.querySelectorAll<HTMLInputElement>('input.js-checkable-item')
  const header = container.querySelector('.js-bulk-actions-header')
  for (const count of container.querySelectorAll('.js-bulk-count')) count.textContent = `${allCheckableItems.length}`
  for (const checkbox of allCheckableItems) {
    checkbox.checked = input.checked
  }
  input.indeterminate = false
  if (!input.checked) {
    bulkActions.classList.add('none')
    normalActions.classList.remove('none')
    // New CSS version
    bulkActions.classList.add('hidden')
    normalActions.classList.remove('hidden')
    header.classList.remove('sticky')
  } else {
    bulkActions.classList.remove('none')
    normalActions.classList.add('none')
    // New CSS version
    bulkActions.classList.remove('hidden')
    normalActions.classList.add('hidden')
    header.classList.add('sticky')
  }
})

on('change', '.js-bulk-actions-container', (event) => {
  const container = event.currentTarget
  const ids = Array.from(container.querySelectorAll<HTMLInputElement>('input.js-checkable-item'))
    .filter((i) => i.checked)
    .map((item) => item.getAttribute('data-id'))
    .join(',')

  for (const fields of container.querySelectorAll<HTMLInputElement>('input.js-bulk-ids')) {
    fields.value = ids
  }

  const form = container.querySelector<HTMLFormElement>('form.js-bulk-ids')
  if (!form) return

  const url = new URL(form.action, window.location.href)
  url.searchParams.set('ids', ids)
  form.action = url.toString()
})

on('change', '.js-tag-card .js-role', (event) => {
  const select = event.currentTarget as HTMLSelectElement
  const tagCard: HTMLElement = select.closest('.js-tag-card')
  const memberIdInput = tagCard.querySelector<HTMLInputElement>('.js-member-id')
  if (select.value === 'admin') {
    memberIdInput.name = 'admin_ids[]'
  } else if (select.value === 'viewer') {
    memberIdInput.name = 'viewer_ids[]'
  } else {
    memberIdInput.name = 'member_ids[]'
  }
})
