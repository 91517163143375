import {observe} from 'selector-observer'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {Turbo} from '@hotwired/turbo-rails'

// like `behaviors/poll.ts` but simpler by using turbo frames
observe('[data-turbo-poll-path]', {
  add(el) {
    const interval = parseInt(el.getAttribute('data-interval')) * 1000 + Math.random() * 2 * 1000
    const path = el.getAttribute('data-turbo-poll-path')
    setTimeout(() => {
      if (!el.isConnected) {
        return
      }

      const frame = el.closest('turbo-frame')?.id
      Turbo.visit(path, {frame})
    }, interval)
  },
})
