import {on} from 'delegated-events'
import {fire} from '@rails/ujs'
import {observe} from 'selector-observer'
import autosize from '@github/textarea-autosize'

on('change', '.js-autosubmit-change', (event) => {
  if (event.currentTarget.hasAttribute('form')) {
    const input = event.currentTarget as HTMLInputElement | HTMLSelectElement
    fire(input.form, 'submit')
  } else {
    const form = event.currentTarget.closest('form')
    fire(form, 'submit')
  }
})

on('search-filter-added', '.js-autosubmit-search-filter', (event) => {
  const input = event.currentTarget.querySelector('[data-search-filter]') as HTMLInputElement
  if (!input || !input.form) return
  fire(input.form, 'submit')
})

on('reset', 'form[data-submit-on-reset]', async (event) => {
  const form = event.currentTarget
  await new Promise((resolve) => setTimeout(resolve, 0))
  fire(form, 'submit')
})

on('keydown', '.js-submit-on-enter', (event) => {
  const form = event.currentTarget.closest('form')
  if (event.key === 'Enter') fire(form, 'submit')
})

on('focusout', '.js-submit-on-focusout', (event) => {
  const form = event.currentTarget.closest('form')
  fire(form, 'submit')
})

on('change', '.js-select-update', (event) => {
  const data = new URLSearchParams(event.currentTarget.getAttribute('data-select-update'))
  for (const inputKey of data.keys()) {
    const [key, attr] = inputKey.split('|', 2)
    const el = event.currentTarget.closest('form').querySelector(key) || document.querySelector(key)
    if (!el) return

    if (!attr) {
      el.textContent = data.get(inputKey)
    } else {
      const val = data.get(inputKey)
      if (val) {
        if (attr === 'checked' && el instanceof HTMLInputElement) el.checked = true
        else if (attr === 'disabled' && el instanceof HTMLSelectElement) el.disabled = true
        else el.setAttribute(attr, val)
      } else {
        if (attr === 'checked' && el instanceof HTMLInputElement) el.checked = false
        else if (attr === 'disabled' && el instanceof HTMLSelectElement) el.disabled = false
        else el.removeAttribute(attr)
      }
    }
  }
})

on('click', '.js-submit', (e) => {
  e.preventDefault()
  const form = document.createElement('form')
  form.setAttribute('method', 'POST')
  form.setAttribute('action', e.currentTarget.getAttribute('data-action'))
  const input = document.createElement('input')
  input.type = 'hidden'
  input.name = 'authenticity_token'
  input.value = document.querySelector<HTMLMetaElement>('meta[name="csrf-token"]').content
  form.appendChild(input)
  document.head.appendChild(form)
  form.submit()
})

on('input', 'input[data-mimicked]', (e) => {
  const input = e.currentTarget as HTMLInputElement
  mimic(input.getAttribute('id'), input.getAttribute('data-mimic-value') || input.value)
})

on('change', 'select[data-mimicked]', (e) => {
  const select = e.currentTarget as HTMLSelectElement
  mimic(select.getAttribute('id'), select.value)
})

function mimic(id: string, value: string) {
  for (const el of document.querySelectorAll(`[data-mimic*="#${id}"]`)) {
    let replaceValue
    if (value === '') {
      replaceValue = el.getAttribute('data-mimic-default') || ''
    } else {
      replaceValue = `${el.getAttribute('data-mimic-prefix') || ''}${value}`
    }

    el instanceof HTMLInputElement ? (el.value = replaceValue) : (el.textContent = replaceValue)
  }
}

const debounceSubmitTimers = new WeakMap<Element, NodeJS.Timeout>()

on('input', 'input[data-debounce-submit]', (e) => {
  const input = e.currentTarget as HTMLInputElement
  const timer = debounceSubmitTimers.get(input)
  if (timer) clearTimeout(timer)

  debounceSubmitTimers.set(
    input,
    setTimeout(() => {
      const form = input.form
      const submitter = document.createElement('input')
      submitter.type = 'submit'
      submitter.hidden = true
      form.appendChild(submitter)
      submitter.click()
      form.removeChild(submitter)
    }, 250)
  )
})

const debounceMergeURLTimers = new WeakMap<Element, NodeJS.Timeout>()

on('input', 'input[data-debounce-merge-url]', (e) => {
  const input = e.currentTarget as HTMLInputElement
  const timer = debounceMergeURLTimers.get(input)
  if (timer) clearTimeout(timer)

  debounceMergeURLTimers.set(
    input,
    setTimeout(() => {
      const form = input.form
      const formData = new FormData(form)
      const documentURL = new URL(window.location.href)

      for (const formField of formData.keys()) {
        const formValue = formData.get(formField)
        if (formField === 'authenticity_token' || typeof formValue !== 'string') continue
        documentURL.searchParams.set(formField, formValue)
      }

      history.replaceState({}, null, documentURL.href)
      const submitter = document.createElement('input')
      submitter.type = 'submit'
      submitter.hidden = true
      form.appendChild(submitter)
      submitter.click()
      form.removeChild(submitter)
    }, 250)
  )
})

// Conditionally makes inputs data-turbo-permanent when input is used
on('input', 'input[data-turbo-perma-synced-input]', (e) => {
  e.currentTarget.setAttribute('data-turbo-permanent', 'true')
  e.currentTarget.setAttribute('data-turbo-perma-synced-input', 'true')
})

// Disables perma-syncing when making a non-history visit
on('turbo:before-visit', 'html', () => {
  for (const syncedInput of document.querySelectorAll<HTMLInputElement>('input[data-turbo-perma-synced-input]')) {
    syncedInput.setAttribute('data-turbo-perma-synced-input', 'false')
  }
})

// Syncs perma-synced inputs when making history visits
on('turbo:load', 'html', () => {
  for (const syncedInput of document.querySelectorAll<HTMLInputElement>('input[data-turbo-perma-synced-input]')) {
    const value = new URLSearchParams(window.location.search).get(syncedInput.name)
    if (syncedInput.getAttribute('data-turbo-perma-synced-input') === 'true') {
      syncedInput.value = value
    }

    syncedInput.setAttribute('data-turbo-perma-synced-input', 'true')
  }
})

on('change', '.js-radio-dependency-option', (event) => {
  const wrapper = event.currentTarget.closest('.js-radio-dependency')
  const dependents = wrapper.querySelectorAll<HTMLInputElement | HTMLFieldSetElement>(
    'input.js-radio-dependency-dependent,fieldset.js-radio-dependency-dependent'
  )
  for (const dependent of dependents) {
    const closestWrapper = dependent.closest('.js-radio-dependency')
    // Don't allow intances of radio dependency to reach into nested instances
    if (closestWrapper === dependent || closestWrapper === wrapper) {
      dependent.disabled = event.currentTarget.classList.contains('js-radio-dependency-unmet')
      dependent.hidden = event.currentTarget.classList.contains('js-radio-dependency-hide-unmet')
    }
  }
})

on('turbo:submit-end', 'form[data-reset-on-success]', (event) => {
  if (!event.detail.success) return
  ;(event.currentTarget as HTMLFormElement).reset()
})

const focusMap = new WeakMap<HTMLElement, HTMLElement>()

on('turbo:submit-start', '[data-listitem-focus-fallback-id]', (event) => {
  const listItems = Array.from(event.currentTarget.querySelectorAll('li, [role="listitem"]'))
  if (listItems.length <= 1) return

  const currentIndex = listItems.indexOf(event.detail.formSubmission.submitter.closest('li, [role="listitem"]'))
  const targetIndex = currentIndex + 1 === listItems.length ? 0 : currentIndex + 1
  const targetElement = listItems[targetIndex]?.querySelector(event.detail.formSubmission.submitter.tagName)
  if (targetElement) focusMap.set(event.detail.formSubmission.submitter, targetElement)
})

on('turbo:submit-end', '[data-listitem-focus-fallback-id]', (event) => {
  if (!event.detail.success) return
  const target =
    focusMap.get(event.detail.formSubmission.submitter) ||
    document.getElementById(event.currentTarget.getAttribute('data-listitem-focus-fallback-id'))
  if (target) target.focus()
})

function toggleFileInput(form: HTMLElement, filename?: string, autofocus?: boolean) {
  const fileNameContainer = form.querySelector('[data-file-name-container]')
  fileNameContainer.textContent = filename

  const fileInputContainer = form.querySelector('[data-file-input-toggle-container]')
  fileInputContainer.classList.toggle('hidden', !filename)

  form.querySelector('[data-file-upload-error]')?.classList.add('hidden')

  if (!autofocus || !filename) return
  fileInputContainer.querySelector<HTMLElement>('[autofocus]')?.focus()
}

on('change', 'form[data-file-input-toggle]', (e) => {
  const input = e.currentTarget.querySelector<HTMLInputElement>('input[data-get-uploaded-file-name]')
  const filename = input.files.length > 0 ? input.files[0].name : null
  toggleFileInput(e.currentTarget as HTMLElement, filename, e.target === input)
  if (!filename) input.labels[0].focus()
})

on('reset', 'form[data-file-input-toggle]', (e) => {
  toggleFileInput(e.currentTarget as HTMLElement)
  e.currentTarget.querySelector<HTMLInputElement>('input[data-get-uploaded-file-name]')?.focus()
})

on('change', '.js-clear-upload', (e) => {
  const container = e.currentTarget.closest('form')
  for (const file of container.querySelectorAll<HTMLInputElement>('input[type="file"]')) file.value = null
})

on('change', '.js-preview-image', (e) => {
  const fileInput = e.currentTarget as HTMLInputElement
  const container = fileInput.closest('form')
  const preview = container.querySelector<HTMLImageElement>('img.js-thumbnail-preview')
  preview.src = URL.createObjectURL(fileInput.files[0])
})

on('click', '[data-focus-field]', () => {
  const field = document.getElementById((event.currentTarget as HTMLElement).getAttribute('data-focus-field'))
  field?.focus()
})

observe('input[data-set-default-empty][value]', {
  constructor: HTMLInputElement,
  initialize(input) {
    const value = input.value
    input.defaultValue = ''
    input.value = value
  },
})

observe('[data-textarea-autoresize]', {
  subscribe: autosize,
})

// `autoresize` does not resize elements that are not _entirely_ visible in the viewport
observe('[data-textarea-autoresize]', (textarea) => {
  intersectionObserver.observe(textarea)
})

const intersectionObserver = new IntersectionObserver(
  (entries) => {
    for (const entry of entries) {
      if (entry.isIntersecting) {
        // viewportMarginBottom prevents a small maxHeight from being set by `autosize`
        autosize(entry.target, {viewportMarginBottom: -1000})
      }
    }
  },
  {threshold: 1}
)

// Autoformat input with commas
on('input', 'input[data-input-with-commas]', (event) => {
  const input = event.currentTarget as HTMLInputElement
  let value = input.value.replace(/[^0-9]/g, '')
  value = Number(value).toLocaleString()
  input.value = value
})
