import {observe} from 'selector-observer'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {Turbo} from '@hotwired/turbo-rails'
import Combobox from '@github/combobox-nav'

class TurboAutocomplete {
  element: HTMLDivElement
  input: HTMLInputElement
  frame: HTMLElement
  src: string
  inputName: string
  query: string
  multiple: boolean
  values: string[] = []
  combobox: Combobox
  clicking: boolean

  constructor(el: HTMLDivElement) {
    this.element = el
    this.src = this.element.getAttribute(`data-src`)
    this.inputName = this.element.getAttribute(`data-input-name`)
    this.frame = this.element.querySelector(`#${this.element.getAttribute(`data-turbo-frame`)}`)
    this.input = this.element.querySelector<HTMLInputElement>(`[data-turbo-autocomplete-input]`)
    this.clicking = false
    this.multiple = !!this.inputName.match(/\[\]$/)
    this.frame.addEventListener('turbo:frame-render', this.frameRendered.bind(this))
    this.input.addEventListener('keydown', this.onKeydown.bind(this))
    this.input.addEventListener('focus', this.onInputFocus.bind(this))
    this.input.addEventListener('blur', this.hideResults.bind(this))
    this.input.addEventListener('input', this.onInputChange.bind(this))
    this.initalizeValues()
    this.frameRendered()
  }

  get results() {
    return this.element.querySelector<HTMLElement>(`[data-turbo-autocomplete-results]`)
  }

  get selections() {
    return this.element.querySelector<HTMLElement>(`[data-turbo-autocomplete-selections]`)
  }

  destroy() {
    this.frame.removeEventListener('turbo:frame-render', this.frameRendered.bind(this))
    this.input.removeEventListener('keydown', this.onKeydown.bind(this))
    this.input.removeEventListener('focus', this.onInputFocus.bind(this))
    this.input.removeEventListener('blur', this.hideResults.bind(this))
    this.input.removeEventListener('input', this.onInputChange.bind(this))
  }

  onKeydown(event: KeyboardEvent): void {
    if (event.key === `Escape`) {
      this.hideResults()
      event.stopPropagation()
      event.preventDefault()
      return
    }
  }

  get resultsShowing(): boolean {
    return !this.results.classList.contains(`hidden`)
  }

  showResults() {
    this.combobox?.start()
    this.element.setAttribute('data-showing-results', 'true')
  }

  hideResults() {
    if (this.clicking) {
      this.clicking = false
      return
    }
    this.combobox?.stop()
    this.element.removeAttribute('data-showing-results')
  }

  onInputFocus() {
    this.showResults()
    this.fetchResults()
  }

  debounceTimeout: NodeJS.Timeout

  onInputChange() {
    if (this.debounceTimeout) clearTimeout(this.debounceTimeout)

    this.debounceTimeout = setTimeout(this.fetchResults.bind(this), 250)
  }

  onResultsMouseDown() {
    this.clicking = true
  }

  onCommit({target}: Pick<Event, 'target'>) {
    if (!(target instanceof HTMLElement)) return
    this.hideResults()
    if (target instanceof HTMLAnchorElement) return

    const value = target.getAttribute(`data-autocomplete-value`)
    this.query = null
    this.setValue(value)
    this.input.value = null
    this.turboRequest()
  }

  initalizeValues() {
    for (const selected of this.selections.querySelectorAll(`[data-autocomplete-value]`)) {
      const value = selected.getAttribute(`data-autocomplete-value`)
      this.setValue(value)
    }
  }

  setValue(value: string) {
    if (this.multiple && !this.values.includes(value)) {
      this.values = [...this.values, value]
    } else {
      this.values = [value]
    }
    this.valuesChanged()
  }

  turboRequest() {
    const url = new URL(this.src, window.location.href)
    const params = new URLSearchParams(url.search.slice(1))
    if (this.query) {
      params.append(`q`, this.query)
    }
    for (const value of this.values) {
      params.append(`v[]`, value.toString())
    }
    params.append(`input_name`, this.inputName)
    url.search = params.toString()

    this.element.classList.add(`is-loading`)
    this.results.removeEventListener('mousedown', this.onResultsMouseDown.bind(this))
    this.results.removeEventListener('combobox-commit', this.onCommit.bind(this))
    this.selections.removeEventListener('click', this.maybeRemoveResult.bind(this))
    Turbo.visit(url, {frame: this.frame.id, action: `replace`})
  }

  fetchResults() {
    const query = this.input.value.trim()
    if (!query || query === this.query) {
      return
    }

    this.query = query
    this.turboRequest()
  }

  frameRendered() {
    if (this.element.classList.contains(`is-loading`)) this.input.focus()
    this.element.classList.remove(`is-loading`)
    this.combobox?.destroy()
    this.combobox = new Combobox(this.input, this.results)
    this.combobox.start()
    this.results.addEventListener('mousedown', this.onResultsMouseDown.bind(this))
    this.results.addEventListener('combobox-commit', this.onCommit.bind(this))
    this.selections.addEventListener('click', this.maybeRemoveResult.bind(this))
  }

  maybeRemoveResult(event: MouseEvent) {
    if (!(event.target instanceof Element)) return
    const withinButton = event.target.closest('[data-turbo-autocomplete-remove]')
    if (!withinButton) return
    const option = event.target.closest(`[role=option]`)
    const value = option.getAttribute('data-autocomplete-value')
    option.remove()
    this.values = this.values.filter((v) => v !== value)
    this.valuesChanged()
  }

  valuesChanged() {
    const event = new CustomEvent(`turbo-autocomplete:values-changed`, {detail: this.values})
    this.element.dispatchEvent(event)
  }
}

interface AutocompleteRegistry {
  [key: string]: TurboAutocomplete
}

const autocompletes: AutocompleteRegistry = {}

observe('[data-turbo-autocomplete]', {
  add(el) {
    const turboAutocomplete = new TurboAutocomplete(el as HTMLDivElement)
    autocompletes[el.getAttribute('data-turbo-frame')] = turboAutocomplete
  },
  remove(el) {
    const turboAutocomplete = autocompletes[el.getAttribute('data-turbo-frame')]
    turboAutocomplete.destroy()
  },
})
