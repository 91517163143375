import {on} from 'delegated-events'

let lastOpenedDetails: HTMLDetailsElement | null = null

function relocateSectionToolbar(event: Event) {
  const toolbarDetails = event.currentTarget as HTMLDetailsElement
  const section = toolbarDetails.closest('[data-transcript-section]')
  const toolbar = document
    .querySelector<HTMLTemplateElement>('template[data-section-toolbar-tpl]')
    .content.cloneNode(true) as HTMLElement
  const existingToolbar = section.querySelector('[data-section-toolbar]')

  if (toolbarDetails.open && lastOpenedDetails && lastOpenedDetails !== toolbarDetails) {
    // Collapse details of section that previously hosted toolbar
    lastOpenedDetails.open = false
  } else if (!toolbarDetails.open && existingToolbar) {
    // Remove toolbar when closing details
    existingToolbar.remove()
    return
  }
  lastOpenedDetails = toolbarDetails

  // Get formatted time from section and replace text nodes
  const formattedTimes = toolbar.querySelectorAll('[data-section-time-formatted]')
  for (const formattedTimeEl of formattedTimes) {
    formattedTimeEl.textContent = section.getAttribute('data-section-time-formatted')
  }

  // Get link & frame ID for new chapter and update toolbar href + frame attribute
  const chapterLink = toolbar.querySelector<HTMLAnchorElement>('[data-chapter-at-selection]')
  if (chapterLink) {
    chapterLink.href = section.getAttribute('data-section-chapter-path')
    chapterLink.setAttribute('data-turbo-frame', section.getAttribute('data-section-chapter-frame'))

    // Hide the chapter button when there isn't a valid chapter path (no permission, chapter exists, etc.)
    chapterLink.classList.toggle('hidden', section.getAttribute('data-section-chapter-path') === 'false')
  }

  // Get link & frame ID for editing and update toolbar href + frame attribute
  const editLink = toolbar.querySelector<HTMLAnchorElement>('[data-section-edit-link]')
  if (editLink) {
    editLink.href = section.getAttribute('data-section-edit-path')
    editLink.setAttribute('data-turbo-frame', section.getAttribute('data-section-edit-frame'))
  }

  if (!existingToolbar) {
    toolbarDetails.append(toolbar)
  }
}

function openSectionOptionsDetails(event: Event) {
  const section = event.currentTarget as HTMLElement
  const sectionOptionsDetails = section.querySelector<HTMLDetailsElement>('details[data-transcript-section-options]')
  if (sectionOptionsDetails) sectionOptionsDetails.open = true
}

function closeSectionOptionsDetails(event: Event) {
  if (event.target === event.currentTarget) {
    const section = event.currentTarget as HTMLElement
    const sectionOptionsDetails = section.querySelector<HTMLDetailsElement>('details[data-transcript-section-options]')
    if (sectionOptionsDetails) sectionOptionsDetails.open = false
  }
}

on('mouseenter', '[data-transcript-section]', openSectionOptionsDetails, {capture: true})

on('mouseleave', '[data-transcript-section]', closeSectionOptionsDetails, {capture: true})

on('toggle', '[data-transcript-section-options]', relocateSectionToolbar, {capture: true})

document.addEventListener('selectionchange', () => {
  const selection = document.getSelection()
  if (selection.anchorNode === selection.focusNode) return
  const belongsToSection = document.getSelection().focusNode?.parentElement?.closest('[data-transcript-section]')
  if (!belongsToSection) return
  belongsToSection.querySelector<HTMLDetailsElement>('[data-transcript-section-options]').open = true
})
