import {TurboFrameMissingEvent} from 'types'
import {flashMessage} from 'behaviors/flash-message'
import {reportError} from 'error-report'

// TODO: figure out how to properly extend the CustomDelegatedEventListener
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
document.addEventListener(`turbo:frame-missing`, (e: TurboFrameMissingEvent) => {
  const {status} = e.detail.response
  if (status < 200 || status > 299) return

  e.preventDefault()
  flashMessage(`Something went wrong. Try again or contact our support team for assistance.`, `alert`)
  // returns a readable identifier of the active element like '<a href="/foo" class="bar">'
  const activeElementMatches = document.activeElement?.outerHTML?.match(/<[^>]+>/)
  reportError(`TurboFrameMissingError`, {
    frameId: e.target.id,
    fromUrl: e.currentTarget.documentURI,
    toUrl: e.detail.response.url,
    activeElement: activeElementMatches ? activeElementMatches[0] : null,
  })
})
