import {observe} from 'selector-observer'

observe('[data-scroll-to-element]', {
  constructor: HTMLElement,
  initialize(container) {
    const elementId = container.getAttribute('data-scroll-to-element')
    if (!elementId) return

    const behavior = container.getAttribute('data-scroll-to-element-behavior') as ScrollBehavior
    scrollToElementById(elementId, behavior)
  },
})

observe('[data-scroll-to-self]', {
  constructor: HTMLElement,
  initialize(element) {
    setTimeout(() => element.scrollIntoView({behavior: 'smooth', block: 'nearest'}), 0)
  },
})

function scrollToElementById(elementId: string, behavior?: ScrollBehavior) {
  const element = document.querySelector(`#${elementId}`)
  if (!element) return

  setTimeout(() => {
    element.scrollIntoView({behavior: behavior || 'auto'})
  }, 0)
}
