import {observe} from 'selector-observer'
import {on} from 'delegated-events'

observe('[data-state-inherited]', (el) => {
  observeSelectorFor(el, el.getAttribute('data-state-inherited'))
})

observe('[data-state]', (el) => {
  observeSelectorFor(el, `[data-state]${el.getAttribute('data-state')}`)
})

on('click', '[data-state-target]', (e) => {
  const element = e.currentTarget as HTMLElement
  updateDataState(element)
})

function updateDataState(el: HTMLElement) {
  const annotationEl = document.querySelector<HTMLElement>(`${el.getAttribute('data-state-target')}`)
  if (annotationEl) annotationEl.setAttribute(el.getAttribute('data-state-attr'), el.getAttribute('data-state-value'))
}

function observeSelectorFor(el: Element, selector: string) {
  const toggleClasses = el.getAttribute('data-state-toggle-class')?.split(' ')
  const force = el.hasAttribute('data-state-toggle-force')
    ? el.getAttribute('data-state-toggle-force') === 'true'
    : undefined
  const toggleReplaceClasses = el.getAttribute('data-state-toggle-replace-class')
  const replaceClasses = el.getAttribute('data-state-replace-class')
  const addClasses = el.getAttribute('data-state-add-class')?.split(' ')
  const removeClasses = el.getAttribute('data-state-remove-class')?.split(' ')
  const originalClasses = el.className

  const removeAttributes = el.getAttribute('data-state-remove-attribute')?.split(' ')

  observe(selector, {
    add(observed) {
      if (!targetIsRelevant(observed, el)) return
      if (toggleClasses?.length > 0) {
        for (const className of toggleClasses) {
          el.classList.toggle(className, force)
        }
      }
      if (toggleReplaceClasses) el.className = toggleReplaceClasses
      if (replaceClasses) el.className = replaceClasses
      if (addClasses?.length > 0) el.classList.add(...addClasses)
      if (removeClasses?.length > 0) el.classList.remove(...removeClasses)
      if (removeAttributes?.length > 0) {
        for (const attribute of removeAttributes) {
          el.removeAttribute(attribute)
        }
      }
      el.querySelector<HTMLElement>('[data-focusable]')?.focus()
    },
    remove(observed) {
      if (!targetIsRelevant(observed, el)) return
      if (toggleClasses?.length > 0) {
        for (const className of toggleClasses) {
          el.classList.toggle(className, force === undefined ? undefined : !force)
        }
      }
      if (toggleReplaceClasses) el.className = originalClasses
    },
  })
}

function targetIsRelevant(observed: Element, el: Element) {
  if (el.hasAttribute('data-state-inherited')) {
    return observed.contains(el) && observed !== el
  } else {
    return observed === el
  }
}
