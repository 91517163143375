// Copy-and-pasted segment script with only a little bit of customization at analytics.load().
// https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/quickstart/#step-2-add-the-segment-snippet

/* eslint eslint-comments/no-use: off */
/* eslint eslint-comments/no-unlimited-disable: off */
/* eslint-disable */

if (process.env.NODE_ENV !== 'production') {
  window.analytics = {
    trackSubmit: function () {},
    trackClick: function () {},
    trackLink: function () {},
    trackForm: function () {},
    pageview: function () {},
    identify: function (a, b, c, d) {
      return new Promise((resolve) => d())
    },
    reset: function () {},
    group: function (a, b, c, d) {
      return new Promise((resolve) => d())
    },
    track: function () {},
    ready: function () {},
    alias: function () {},
    debug: function () {},
    page: function () {},
    once: function () {},
    off: function () {},
    on: function () {},
    addSourceMiddleware: function () {},
    addIntegrationMiddleware: function () {},
    setAnonymousId: function () {},
    addDestinationMiddleware: function () {},
  }
} else {
  !(function () {
    var analytics = (window.analytics = window.analytics || [])
    if (!analytics.initialize)
      if (analytics.invoked) window.console && console.error && console.error('Segment snippet included twice.')
      else {
        analytics.invoked = !0
        analytics.methods = [
          'trackSubmit',
          'trackClick',
          'trackLink',
          'trackForm',
          'pageview',
          'identify',
          'reset',
          'group',
          'track',
          'ready',
          'alias',
          'debug',
          'page',
          'once',
          'off',
          'on',
          'addSourceMiddleware',
          'addIntegrationMiddleware',
          'setAnonymousId',
          'addDestinationMiddleware',
        ]
        analytics.factory = function (e) {
          return function () {
            var t = Array.prototype.slice.call(arguments)
            t.unshift(e)
            analytics.push(t)
            return analytics
          }
        }
        for (var e = 0; e < analytics.methods.length; e++) {
          var key = analytics.methods[e]
          analytics[key] = analytics.factory(key)
        }
        analytics.load = function (key, e) {
          var t = document.createElement('script')
          t.type = 'text/javascript'
          t.async = !0
          t.src = 'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js'
          var n = document.getElementsByTagName('script')[0]
          n.parentNode.insertBefore(t, n)
          analytics._loadOptions = e
        }
        analytics._writeKey = 'FXBj0ic5csO7p11X41QkLzGv3sMq99pE'
        analytics.SNIPPET_VERSION = '4.13.2'
        const domainPieces = window.location.hostname.split('.')
        if (
          domainPieces.length < 3 ||
          domainPieces[0] == 'staging' ||
          window.location.pathname.startsWith('/onboarding/')
        ) {
          // for the logged out views
          analytics.load(analytics._writeKey, {
            initialPageview: false,
          })
        } else {
          //for the logged in views
          analytics.load(analytics._writeKey, {
            initialPageview: false,
            integrations: {
              All: false,
              'Segment.io': true,
              Amplitude: true,
              Intercom: true,
            },
          })
        }
      }
  })()
}
