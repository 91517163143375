import {on} from 'delegated-events'

// window.Intercom is loaded by segment.io initializer script.
// https://segment.com/docs/connections/destinations/catalog/intercom/#client
// https://developers.intercom.com/installing-intercom/docs/intercom-javascript

declare global {
  interface Window {
    Intercom?(arg0: string, arg1?: unknown): unknown
  }
}
on('click', '.js-launch-intercom-help', (event) => {
  if (window.Intercom) {
    event.preventDefault()
    window.Intercom('show')
  }
})
