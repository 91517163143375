import {on} from 'delegated-events'

on('click', '[data-select-tab-target]', (event) => {
  const selectedTabId = event.currentTarget.getAttribute('data-select-tab-target')
  selectTabById(selectedTabId)
})

document.addEventListener('change-video-tabs', (event) => {
  const {id} = event.detail
  if (id) selectTabById(id)
})

function selectTabById(id: string) {
  const selectedTab = document.querySelector(`[data-tab-button-id="${id}"]`)
  if (id) {
    selectedTab.setAttribute('aria-selected', 'true')

    // Toggle aria-pressed state
    const tabContainer = selectedTab.closest('[data-js-tab-container]')
    tabContainer.setAttribute('data-active-tab', id)

    // Scroll tab content to top
    if (selectedTab.hasAttribute('data-scroll-tab-content-to-top')) {
      window.scrollTo({top: 0})
    }

    const tabs = tabContainer.querySelectorAll('[data-select-tab-target]')
    if (tabs) {
      for (let i = 0; i < tabs.length; i++) {
        if (tabs[i] !== selectedTab) {
          tabs[i].setAttribute('aria-selected', 'false')
        }
      }
    }
  }
}
