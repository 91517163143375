import {
  delegate,
  linkDisableSelector,
  enableElement,
  buttonDisableSelector,
  formSubmitSelector,
  disableElement,
} from '@rails/ujs'

// workaround turbo bug to fix `data-disable` -- https://github.com/hotwired/turbo-rails/issues/135#issuecomment-814413558
delegate(document, linkDisableSelector, 'turbo:before-cache', enableElement)
delegate(document, buttonDisableSelector, 'turbo:before-cache', enableElement)
delegate(document, buttonDisableSelector, 'turbo:submit-end', enableElement)
delegate(document, formSubmitSelector, 'turbo:submit-start', disableElement)
delegate(document, formSubmitSelector, 'turbo:submit-end', enableElement)
delegate(document, formSubmitSelector, 'turbo:before-cache', enableElement)
