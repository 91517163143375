import {observe} from 'selector-observer'
import {on} from 'delegated-events'

observe('.js-toggle-container input.js-toggle', {
  constructor: HTMLInputElement,
  initialize(toggle) {
    const container = toggle.closest('.js-toggle-container')
    container.toggleAttribute('data-toggle-active', toggle.checked)
  },
})

on('change', 'input.js-toggle', (event) => {
  const input = event.currentTarget as HTMLInputElement
  const form = input.closest('.js-toggle-container') || input.form
  if (input.checked) form.setAttribute('data-toggle-active', '')
  else form.removeAttribute('data-toggle-active')

  const showChecked = form.querySelectorAll('.js-toggle-show-checked')
  const showUnchecked = form.querySelectorAll('.js-toggle-show-unchecked')
  if (
    (input.type === 'checkbox' && input.checked) ||
    (input.type !== 'checkbox' && input.classList.contains('js-toggle'))
  ) {
    for (const item of showUnchecked) {
      // New CSS versions
      item.classList.add('none', 'hidden')
    }
    for (const item of showChecked) {
      item.classList.remove('none', 'hidden')
    }
  } else {
    for (const item of showChecked) {
      item.classList.add('none', 'hidden')
    }
    for (const item of showUnchecked) {
      item.classList.remove('none', 'hidden')
    }
  }
})

on('ajax:before', '.js-toggle-container', (e) => {
  for (const node of e.currentTarget.children) {
    node.classList.toggle('none')
    // New CSS version
    node.classList.toggle('hidden')
  }
})

observe('.js-expandable', (el) => {
  for (const button of el.querySelectorAll('.js-expand-on-click')) {
    button.setAttribute('aria-expanded', el.classList.contains('expanded').toString())
  }
})

observe('.js-expandable.expanded', (el) => {
  function toggle() {
    for (const button of el.querySelectorAll('.js-expand-on-click')) {
      button.setAttribute('aria-expanded', el.classList.contains('expanded').toString())
    }
  }

  return {add: toggle, remove: toggle}
})

on('click', '.js-expand-on-click', (e) => {
  const element = e.target as HTMLElement
  //prevent losing focus when clicking on an image caption in the trix editor
  if (element.getAttribute('data-trix-mutable') === 'true') return

  const button = e.currentTarget as HTMLElement
  if (!button.hasAttribute('data-allow-default')) e.preventDefault()
  if (button.hasAttribute('data-stop-propagation')) e.stopPropagation()
  const container = button.closest('.js-expandable')
  container.classList.add('expanded')
  container.querySelector<HTMLElement>('[data-focusable], [autofocus]')?.focus()
})

on('click', '.js-collapse-on-click', (e) => {
  e.stopPropagation()
  const button = e.currentTarget as HTMLElement
  if (!button.hasAttribute('data-allow-default')) e.preventDefault()
  if (button.hasAttribute('data-stop-propagation')) e.stopPropagation()
  const container = button.closest('.js-expandable')
  container.classList.remove('expanded')
  container.querySelector<HTMLElement>('.js-expand-on-click')?.focus()
})

on('change', '.js-select-content-change', (e) => {
  const input = e.currentTarget as HTMLInputElement
  const contentIDattr = e.currentTarget.getAttribute('data-content-id') || ''
  const contentIDs = contentIDattr.length > 0 ? contentIDattr.split(',') : []
  const container = e.currentTarget.closest('.js-select-content-container')

  for (const el of container.querySelectorAll('.js-select-content')) el.classList.add('hidden')

  if (input.checked) {
    for (const id of contentIDs) {
      const showElement = container.querySelector(`#${id}`)
      if (showElement) showElement.classList.remove('hidden')
    }
  }
})

on('change', '[data-toggle-by-selection-select]', (e) => {
  const selectedElement = e.currentTarget as HTMLSelectElement
  const region = e.currentTarget.closest('[data-toggle-by-selection]')

  for (const container of region.querySelectorAll('[data-toggle-by-selection-container]')) {
    const containerValue = container.getAttribute('data-toggle-by-selection-container')
    container.classList.toggle('hidden', containerValue !== selectedElement.value)
  }
})

on('click', '[data-toggle-container-id]', ({currentTarget}) => {
  const toggleGroup = currentTarget.closest('[data-toggle-group]')

  if (toggleGroup) {
    if (currentTarget.getAttribute('aria-expanded') === 'true') return
    for (const button of toggleGroup.querySelectorAll('[data-toggle-container-id]')) {
      if (button === currentTarget) continue
      toggleContainer(button, false)
    }
  }

  toggleContainer(currentTarget)
})

function toggleContainer(button: Element, force: boolean = null) {
  const toggleOpen = force === null ? button.getAttribute('aria-expanded') !== 'true' : force

  button.setAttribute('aria-expanded', toggleOpen.toString())
  const id = button.getAttribute('data-toggle-container-id')
  const container = document.getElementById(id)
  container.classList.toggle('hidden', !toggleOpen)
  if (toggleOpen) {
    container.querySelector<HTMLElement>('[autofocus]')?.focus()
  } else {
    document.querySelector<HTMLElement>(`[data-toggle-container-id='${id}'][aria-expanded="true"]`)?.focus()
  }

  for (const target of document.querySelectorAll(`[data-toggle-container-id='${id}']`)) {
    target.setAttribute('aria-expanded', toggleOpen.toString())
  }
}
