import {observe} from 'selector-observer'

observe('trix-editor', () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  import(/* webpackChunkName: "editor" */ '../editor')
  import(/* webpackChunkName: "suggesters" */ '../suggesters/index')
})

observe('rewatch-video', () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  import(/* webpackChunkName: "video-player" */ '../video/video-player')
})

observe('rewatch-video-events', () => {
  import(/* webpackChunkName: "video-events" */ '../video/video-events')
})

observe('[data-controller~=animated-details]', () => {
  import('../controllers/animated_details_controller')
})

observe('[data-controller~=submenu]', () => {
  import('../controllers/submenu_controller')
})

observe('[data-controller~=clip]', () => {
  import('../controllers/clip_controller')
})

observe('[data-controller~=local-state-details]', () => {
  import('../controllers/local_state_details_controller')
})

observe('[data-controller~=form]', () => {
  import('../controllers/form_controller')
})

observe('[data-controller~=turbo-frame-lazy]', () => {
  import('../controllers/turbo_frame_lazy_controller')
})

observe('[data-controller~=turbo-frame-stream]', () => {
  import('../controllers/turbo_frame_stream_controller')
})

observe('rewatch-highlight-track', () => {
  import(/* webpackChunkName: "highlight-track" */ '../annotations/rewatch-highlight-track-element')
})

observe('#videoChart', () => {
  import('../analytics')
})

observe('#channel_analytics_engagement_chart', () => {
  import('../channel_analytics')
})

observe('.js-datepicker', () => {
  import('../date-picker')
})

observe('.js-google-picker', () => {
  import(/* webpackChunkName: "gdrive_picker" */ '../gdrive-picker')
})

observe('rewatch-transcript', () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  import(/* webpackChunkName: "transcript" */ '../transcript')
})

observe('#series-transcript-container', () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  import(/* webpackChunkName: "transcript" */ '../series-transcript')
})

observe('[data-collection-edit]', () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  import(/* webpackChunkName: "collection-edit" */ '../collection-edit')
})

observe('#ref-video-share-settings', () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  import(/* webpackChunkName: "visibility-form" */ '../video/visibility-form')
})

observe('#privacy_settings', () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  import(/* webpackChunkName: "synced-meeting" */ '../synced-meeting')
})

observe('[data-track-autocomplete-collections]', () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  import(/* webpackChunkName: "track-public-collections" */ './track-autocomplete-collections')
})
