import {observe} from 'selector-observer'
import {on} from 'delegated-events'

const resizeObserver = new ResizeObserver((entries) => {
  for (const {target} of entries) {
    checkScrollButtonState(target)
  }
})

observe('[data-horizontal-scroll]', (el) => {
  resizeObserver.observe(el)
})

on(
  'scroll',
  '[data-horizontal-scroll]',
  (event) => {
    checkScrollButtonState(event.currentTarget as Element)
  },
  {capture: true}
)

// Check whether scroll buttons should be hidden if there's no content to scroll to
function checkScrollButtonState(wrapper: Element) {
  const scrollContainer = wrapper.querySelector<Element>('[data-horizontal-scroll-container]')
  // Scroll buttons
  const scrollLeftButton = wrapper.querySelector('[data-horizontal-scroll-left]')
  const scrollRightButton = wrapper.querySelector('[data-horizontal-scroll-right]')

  const scrollLeft = scrollContainer.scrollLeft
  // Safari rubberbanding
  scrollLeftButton.classList.toggle('hidden', scrollLeft <= 0)
  const hideRightButton =
    Math.round(scrollLeft) === Math.round(scrollContainer.scrollWidth - scrollContainer.clientWidth)
  scrollRightButton.classList.toggle('hidden', hideRightButton)
}

on('click', '[data-horizontal-scroll-left]', (e) => {
  const scrollWrapper = e.currentTarget.closest('[data-horizontal-scroll]')
  const container = scrollWrapper.querySelector('[data-horizontal-scroll-container]')
  container.scrollBy({left: -container.clientWidth, behavior: 'smooth'})
})

on('click', '[data-horizontal-scroll-right]', (e) => {
  const scrollWrapper = e.currentTarget.closest('[data-horizontal-scroll]')
  const container = scrollWrapper.querySelector('[data-horizontal-scroll-container]')
  container.scrollBy({left: container.clientWidth, behavior: 'smooth'})
})
