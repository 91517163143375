import {observe} from 'selector-observer'
import {on} from 'delegated-events'
import type DetailsDialogElement from '@github/details-dialog-element'

observe('details[data-reset-on-close]', {
  constructor: HTMLDetailsElement,
  add(details) {
    const dialog = details.querySelector<DetailsDialogElement>('details-dialog')
    if (!dialog) return

    const children = [...dialog.children].map((child) => child.cloneNode(true))

    function callback() {
      for (const child of [...dialog.children]) {
        child.remove()
      }

      dialog.append(...children.map((child) => child.cloneNode(true)))
    }

    dialog.addEventListener('details-dialog-close', callback)
  },
})

on('ajax:success', '.js-will-dirty-page', () => {
  document.body.classList.add('js-dirty-page')
})

on('turbo:submit-end', '.turbo-will-dirty-page', () => {
  document.body.classList.add('js-dirty-page')
})

on('turbo:click', '.click-will-dirty-page', () => {
  document.body.classList.add('js-dirty-page')
})

on('details-dialog-close', '.js-dirty-page', () => {
  // eslint-disable-next-line no-self-assign
  window.location = window.location
})

document.addEventListener('turbo:before-cache', () => {
  //collapse all modals before caching
  for (const modal of document.querySelectorAll<DetailsDialogElement>('details-dialog')) {
    if (!modal.classList.contains('js-cache-modal')) {
      modal.toggle(false)
    }
  }
})
