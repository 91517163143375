import {on} from 'delegated-events'
import {RewatchVideoEventType} from './video-events'
import type {RewatchVideoEventsElement} from './video-events'
import {observe} from 'selector-observer'

on('click', '[data-highlight-poster-button][data-rewatch-video-events-id]', (event) => {
  getRewatchVideoEvents(event.currentTarget).dispatch('HIGHLIGHTS_POSTER_CLICKED')
})

on('click', '[data-toggle-play-highlights][data-rewatch-video-events-id]', (event) => {
  getRewatchVideoEvents(event.currentTarget).dispatch('HIGHLIGHTS_BTN_CLICKED')
})

observe('[data-toggle-play-highlights]', (el) => {
  // eslint-disable-next-line github/no-then
  window.customElements.whenDefined('rewatch-video-events').then(function () {
    const {highlightState} = getRewatchVideoEvents(el)?.getState() || {}
    el.setAttribute('aria-pressed', (highlightState === 'PLAYING_ALL_HIGHLIGHTS').toString())
  })
})

on(
  'CHANGE_HIGHLIGHT_PLAY_STATE',
  'rewatch-video-events',
  ({currentTarget, detail}: RewatchVideoEventType) => {
    const videoId = (currentTarget as Element).id
    const button = document.querySelector(`[data-toggle-play-highlights][data-rewatch-video-events-id="${videoId}"]`)
    if (!button) return
    button.setAttribute('aria-pressed', (detail.highlightState === 'PLAYING_ALL_HIGHLIGHTS').toString())
  },
  {capture: true}
)

function getRewatchVideoEvents(target: Element) {
  const id = target.getAttribute('data-rewatch-video-events-id')
  return document.getElementById(id) as RewatchVideoEventsElement
}
