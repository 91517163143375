import {observe} from 'selector-observer'
import {on} from 'delegated-events'

observe('.js-stripe-container', {
  initialize(el) {
    if (!window.Stripe) {
      //lazy-load the stripe js
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = 'https://js.stripe.com/v3/'
      script.onload = () => setupStripeElements(el)
      document.head.appendChild(script)
    } else {
      setupStripeElements(el)
    }
  },
})

async function setupStripeElements(container: Element) {
  const stripe = window.Stripe(
    process.env.NODE_ENV === 'production'
      ? 'pk_live_A1gymukJcIcKfZU86H8xwYxA00Wkb5HAeg'
      : 'pk_test_pVBCRr2FWK1Y5c9LEleskrEu00Tce0y46H'
  )

  if (container.getAttribute('data-stripe-intent-client-secret') != null) {
    // in this case, we've already taken the payment information but we need
    // to potentially do a verification step (e.g. 3D secure)
    const clientSecret = container.getAttribute('data-stripe-intent-client-secret')
    const intentType = container.getAttribute('data-stripe-intent-type')
    if (intentType === 'setup') {
      await stripe.confirmCardSetup(clientSecret)
    } //if (intentType === 'payment') {
    else {
      await stripe.confirmCardPayment(clientSecret)
    }

    // either success or failure, the backend should get a webhook.
    // the page will detect that and redirect
    return
  }

  // Get color mode setting
  const colorMode = document.documentElement.getAttribute('color-mode')
  const userPrefersDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
  const isDarkMode = colorMode === 'dark' || (colorMode !== 'light' && userPrefersDark)

  const elements = stripe.elements()

  const card = elements.create('card', {
    classes: {
      base: 'form-control',
      focus: 'form-control-focused',
    },
    style: {
      base: {
        color: `${isDarkMode ? '#FFF' : '#1A1B20'}`,
        fontFamily: '-apple-system, BlinkMacSystemFont, segoe ui, helvetica neue, helvetica, ubuntu, arial, sans-serif',

        '::placeholder': {
          color: `${isDarkMode ? '#A3A5AF' : '#696B78'}`,
        },
      },
      invalid: {
        color: `${isDarkMode ? '#FF7575' : '#E61E3F'}`,
      },
    },
    hideIcon: true,
  })

  card.mount('#card-element')

  // Handle real-time validation errors from the card Element.
  card.addEventListener('change', function (event) {
    const displayError = container.querySelector('#card-errors')
    if (event.error) {
      displayError.textContent = event.error.message
    } else {
      displayError.textContent = ''
    }
  })

  // Handle form submission.
  const form = container.querySelector('#payment-form')
  form.addEventListener('submit', async function (event) {
    if (document.querySelector('.js-card-input').classList.contains('hidden')) return
    event.preventDefault()

    const result = await stripe.createToken(card)
    if (result.error) {
      // Inform the user if there was an error.
      const errorElement = document.querySelector('#card-errors')
      errorElement.textContent = result.error.message
    } else {
      // Send the token to your server.
      stripeTokenHandler(container, result.token)
    }
  })

  container.querySelector('.js-stripe-loading').classList.toggle('hidden')
  container.querySelector('.js-stripe-form').classList.toggle('hidden')
}

// Submit the form with the token ID.
function stripeTokenHandler(container: Element, token: stripe.Token) {
  // Insert the token ID into the form so it gets submitted to the server
  const form = container.querySelector<HTMLFormElement>('#payment-form')
  const hiddenInput = document.createElement('input')
  hiddenInput.setAttribute('type', 'hidden')
  hiddenInput.setAttribute('name', 'stripe_token')
  hiddenInput.setAttribute('value', token.id)
  form.appendChild(hiddenInput)
  // Submit the form
  form.submit()
}

function enablePlanCommitForm(planElement: Element) {
  const commitContainer = document.querySelector('#plan-commit-options')
  const commitForm = document.querySelector('#plan-commit-form')
  commitContainer.setAttribute('data-current-commit', planElement.getAttribute('data-commit-to-plan'))
  requestAnimationFrame(() => {
    commitForm.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'})
  })
}

on('click', '[data-commit-to-plan]', (event) => {
  const planEl = event.currentTarget as HTMLElement
  enablePlanCommitForm(planEl)
})

observe('[data-pre-committed-to-plan]', {
  initialize(el) {
    enablePlanCommitForm(el)
  },
})

on('change', '[data-toggle-annual-billing]', (event) => {
  const checkbox = event.currentTarget as HTMLInputElement
  const url = new URL(window.location.toString())
  const searchParams = new URLSearchParams(url.searchParams)

  searchParams.set('billing_yearly', checkbox.checked.toString())

  url.search = `?${searchParams.toString()}`
  history.replaceState(null, '', url.href)
})
