import {on} from 'delegated-events'

on('beforeinput', '.js-sign-in-policy-input', (event) => {
  const label = event.currentTarget.closest('label')
  const radioInput = document.getElementById(label.htmlFor) as HTMLInputElement
  radioInput.checked = true
})

on('change', '.js-sign-in-renewal-form', (event) => {
  const targetInput = event.target as HTMLInputElement
  const signInRenewalDaysInput = document.querySelector('.js-sign-in-policy-input') as HTMLInputElement
  // return if user is typing in the sign in renewal days input field
  if (targetInput === signInRenewalDaysInput) return

  if (targetInput.value === 'days') {
    signInRenewalDaysInput.setAttribute('required', 'required')
  } else {
    signInRenewalDaysInput.removeAttribute('required')
  }
})
