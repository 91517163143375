import {on} from 'delegated-events'

on('change', '.js-preview-image-upload', (el) => {
  const input = el.currentTarget as HTMLInputElement
  if (!input.files || !input.files[0]) return
  const reader = new FileReader()
  reader.onload = (e) => {
    input
      .closest('form')
      .querySelector<HTMLElement>('.js-preview-image-upload-area')
      .setAttribute('style', `background-size: cover; background-image: url(${e.target.result})`)
  }
  reader.readAsDataURL(input.files[0])
})
