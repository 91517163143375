import {on} from 'delegated-events'
import {observe} from 'selector-observer'

on('click', '[data-sidebar-mobile-toggle]', toggleSidebar)
on('click', '[data-sidebar-mobile-toggle-helper]', toggleSidebar)

function toggleSidebar(_event: Event, target?: HTMLElement | null, enabling?: boolean) {
  const sidebar = document.querySelector<HTMLElement>('[data-sidebar]')
  const button = target || document.querySelector<HTMLElement>('button[data-sidebar-mobile-toggle]')
  const toBeExpanded = enabling === true ? false : sidebar.getAttribute('aria-expanded') === 'false'

  if (enabling === false) {
    sidebar.classList.remove('hidden')
    sidebar.removeAttribute('aria-expanded')
    button.removeAttribute('aria-pressed')

    for (const shade of document.querySelectorAll('[data-shade-visible]')) {
      shade.setAttribute('data-shade-visible', 'false')
    }

    return
  }

  const enabled = enabling || sidebar.hasAttribute('aria-expanded')
  if (!enabled) return

  // Remove hidden to prepare for CSS transition
  if (toBeExpanded) sidebar.classList.remove('hidden')

  // ARIA attributes should only show up when sidebar toggler is shown
  requestAnimationFrame(() => {
    sidebar.setAttribute('aria-expanded', toBeExpanded.toString())
    button.setAttribute('aria-pressed', toBeExpanded.toString())
  })

  for (const shade of document.querySelectorAll('[data-shade-visible]')) {
    shade.setAttribute('data-shade-visible', toBeExpanded.toString())
  }

  // Don't manage autofocus if button is just being initialized
  if (enabling === undefined) {
    if (toBeExpanded) {
      sidebar.setAttribute('tabindex', '-1')
      sidebar.focus()
    } else {
      button.focus()
    }
  }

  // Apply hidden so keyboard won't reach the sidebar
  if (enabling === true) {
    requestAnimationFrame(() => {
      sidebar.classList.add('hidden')
    })
  }
}

const intersectionObserver = new IntersectionObserver(
  (entries) => {
    for (const entry of entries) {
      const button = entry.target as HTMLElement
      const isIntersecting = entry.isIntersecting
      toggleSidebar(null, button, isIntersecting)
    }
  },
  {
    root: document.querySelector('html'),
  }
)

on('keydown', '[data-sidebar]', (event) => {
  if (event.key !== 'Escape') return
  if (event.currentTarget.getAttribute('aria-expanded') !== 'true') return

  const target = event.target
  if (target instanceof Element && target.closest('details[open]')) return

  toggleSidebar(null, null, true)
})

observe('button[data-sidebar-mobile-toggle]', (sidebar) => {
  intersectionObserver.observe(sidebar)
})
