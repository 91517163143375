import {on} from 'delegated-events'

function recordHistory() {
  const visitTypeElement = document.querySelector<HTMLMetaElement>('meta[name=history-type]')
  if (!visitTypeElement || !visitTypeElement.content) return

  const maxVisits = visitTypeElement.content === 'search' ? 4 : 12
  const storageKey = `${window.location.hostname}/${
    visitTypeElement.content === 'search' ? 'recent-searches' : 'recent-visits'
  }`
  const visits = JSON.parse(localStorage.getItem(storageKey)) || []
  const visit = generateVisit(visitTypeElement.content)

  if (visit.title === null || visit.title === '') return

  for (const entry of visits) {
    if (entry.path === visit.path) visits.splice(visits.indexOf(entry), 1)
  }

  visits.unshift(visit)
  visits.splice(maxVisits)
  localStorage.setItem(storageKey, JSON.stringify(visits))
}

function generateVisit(visitType: string) {
  return {
    path: visitType === 'search' ? window.location.pathname + window.location.search : window.location.pathname,
    title: visitType === 'search' ? generateSearchTitle() : document.title,
    type: visitType,
  }
}

function generateSearchTitle() {
  const searchParams = new URLSearchParams(window.location.search)
  let title = searchParams.get('q')
  const searchType = window.location.pathname.split('/')[2]

  if (title === null || '') title = 'Search'

  if (searchType === 'videos') {
    if (searchParams.has('collection_ids[]') || searchParams.has('user_ids[]')) {
      const filterCount = searchParams.getAll('collection_ids[]').length + searchParams.getAll('user_ids[]').length
      title += filterCount > 1 ? ` (with ${filterCount} filters)` : ' (with 1 filter)'
    }
  } else {
    title += ` (filtered to ${searchType})`
  }

  return title
}

on('turbo:load', 'html', recordHistory)
