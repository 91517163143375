import {on} from 'delegated-events'
import {FrameElement} from '../types'

on(`change`, `select[data-turbo-frame-reloader]`, (e) => {
  const select = e.target as HTMLSelectElement
  const option = select.options[select.options.selectedIndex]
  const src = option.getAttribute(`data-turbo-frame-src`)
  const turboFrame = document.getElementById(select.getAttribute(`data-turbo-frame-reloader`)) as FrameElement
  if (!src || !turboFrame) {
    return
  }
  for (const frameSelect of turboFrame.querySelectorAll(`select`)) {
    frameSelect.disabled = true
    const emptyOption = document.createElement('option')
    emptyOption.textContent = `Loading options…`
    frameSelect.replaceChildren(emptyOption)
  }
  turboFrame.src = src
})
