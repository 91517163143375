export const reportError = (name: string, params: Record<string, string> = {}) => {
  const body: Record<string, string> = {name}
  for (const key in params) {
    // turn camelCase to underscore
    body[key.replace(/[A-Z]/g, (match) => `_${match.toLowerCase()}`)] = params[key]
  }
  fetch(`/errors`, {
    keepalive: true,
    method: `POST`,
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.head.querySelector<HTMLMetaElement>('meta[name=csrf-token]').content,
    },
    body: JSON.stringify(body),
  })
}
