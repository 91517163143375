// Utility function for converting time
// Modified from https://stackoverflow.com/a/37096512
export function secondsToHms(d: number | string) {
  d = Number(d)
  const h = Math.floor(d / 3600)
  const m = Math.floor((d % 3600) / 60)
  const s = Math.floor((d % 3600) % 60)

  let hDisplay = ''
  let mDisplay = ''
  let sDisplay = ''

  // Hours
  if (h > 0) {
    hDisplay = `${h}:`
  } else {
    hDisplay = ''
  }

  // Minutes
  if (m > 9) {
    mDisplay = `${m}:`
  } else if (m > 0) {
    if (hDisplay === '') mDisplay = `${m}:`
    else mDisplay = `0${m}:`
  } else {
    if (hDisplay === '') mDisplay = '0:'
    else mDisplay = '00:'
  }

  // Seconds
  if (s > 9) {
    sDisplay = `${s}`
  } else if (s > 0) {
    sDisplay = `0${s}`
  } else {
    sDisplay = '00'
  }

  return hDisplay + mDisplay + sDisplay
}

export function hmsToSeconds(hms: string) {
  const [seconds, minutes, hours] = hms.split(':').reverse().map(Number)
  return (hours || 0) * 60 * 60 + (minutes || 0) * 60 + seconds // minutes and hours might be undefined
}

export function msToHms(milliseconds: number | string) {
  return secondsToHms(Number(milliseconds) / 1000)
}

export function hmsToMs(hms: string) {
  return hmsToSeconds(hms) * 1000
}
