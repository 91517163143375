import {ajax} from '@rails/ujs'
import {observe} from 'selector-observer'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {Turbo} from '@hotwired/turbo-rails'

observe('.js-poll-fragment', {
  add(el) {
    const interval = parseInt(el.getAttribute('data-interval')) * 1000 + Math.random() * 2 * 1000
    const path = el.getAttribute('data-poll-path')
    const frame = el.closest('turbo-frame')
    if (frame) {
      pollTurboFrame(frame, el, path, interval)
    } else {
      pollToAjaxInPartial(el, path, interval)
    }
  },
})

function pollTurboFrame(frame: Element, el: Element, path: string, interval: number) {
  const timer = setInterval(() => {
    if (!el.isConnected) {
      clearInterval(timer)
      return
    }
    // need to put a random suffix to workaround the bug where loading the
    // same url twice will be ignored
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    frame.src = `${path}#${Math.random()}`
  }, interval)
}

function pollToAjaxInPartial(el: Element, path: string, interval: number) {
  setTimeout(() => {
    ajax({
      url: path,
      type: 'GET',
      success(data, _status, xhr) {
        el.innerHTML = data.body.innerHTML
        //don't keep polling if we got 20x
        if (xhr.status === 200 && el.isConnected) {
          setTimeout(() => {
            pollToAjaxInPartial(el, path, interval)
          }, interval)
        }
      },
      error(data) {
        //XXX should do a flash
        console.warn(data)
        if (el.isConnected) {
          setTimeout(() => {
            pollToAjaxInPartial(el, path, interval)
          }, interval)
        }
      },
    })
  }, interval)
}

observe('.js-poll-turbo-stream', {
  add(el) {
    const interval = parseInt(el.getAttribute('data-interval')) * 1000 + Math.random() * 2 * 1000
    const path = el.getAttribute('data-poll-path')
    setTimeout(() => pollTurboStream(el, path, interval), interval)
  },
})

function pollTurboStream(el: Element, path: string, interval: number) {
  if (!el.isConnected) return ///stop polling when the element is gone

  const form = document.createElement('form')
  form.setAttribute('method', 'POST')
  form.setAttribute('action', path)
  form.setAttribute('style', 'display:none')
  const input = document.createElement('input')
  input.type = 'hidden'
  input.name = 'authenticity_token'
  input.value = document.querySelector<HTMLMetaElement>('meta[name="csrf-token"]').content
  form.appendChild(input)
  const submit = document.createElement('input')
  submit.type = 'submit'
  form.appendChild(submit)
  document.body.appendChild(form)
  submit.click()

  form.addEventListener('turbo:submit-end', () => {
    form.remove()
    setTimeout(() => pollTurboStream(el, path, interval), interval)
  })
}

observe('.js-poll-for-redirect', {
  add(el) {
    const interval = parseInt(el.getAttribute('data-interval')) * 1000 + Math.random() * 2 * 1000
    const path = el.getAttribute('data-poll-path')
    pollForRedirect(el, path, interval)
  },
})

function pollForRedirect(el: Element, path: string, interval: number) {
  setTimeout(async () => {
    let resp = null
    try {
      resp = await fetch(path)
    } catch (err) {
      console.trace(err)
    }
    if (el.isConnected) {
      if (resp && resp.redirected) {
        Turbo.visit(resp.url)
      } else {
        pollForRedirect(el, path, interval)
      }
    }
  }, interval)
}
