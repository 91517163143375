// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {Turbo} from '@hotwired/turbo-rails'
import {on, fire} from 'delegated-events'
import {observe} from 'selector-observer'
import {ariaNotify} from './aria-notify'
import {RewatchTranscript} from 'transcript'

on('click', '[data-scroll-to-cue]', (event) => {
  const timestamp = event.currentTarget.getAttribute('data-scroll-to-cue')

  const transcript = getTranscript()
  transcript.toggleAutoscroll(false)
  transcript.scrollToTimestamp(parseFloat(timestamp))
})

on('click', '[data-enable-autoscroll]', (event) => {
  const smoothScroll = event.currentTarget.getAttribute('data-enable-autoscroll') === 'smooth'
  const forceScroll = event.currentTarget.hasAttribute('data-force-scroll')

  // use timeout here in case we need to wait sec for the tab to render
  setTimeout(() => {
    const transcript = getTranscript()
    if (!transcript) {
      return
    } // it's possible it hasn't lazy loaded yet

    transcript.toggleAutoscroll(true, {smoothScroll, forceScroll})
  })
})

on('click', '[data-disable-autoscroll]', () => {
  getTranscript().toggleAutoscroll(false)
})

function getTranscript(): RewatchTranscript {
  return document.querySelector<RewatchTranscript>('rewatch-transcript')
}

type TranslationData = {[key: string]: string}
const translations = new WeakMap<Element, TranslationData>()

on('ajax:success', '[data-translate]', (event) => {
  const [data] = event.detail
  const [value, label] = selectedLanuageCodeAndLabel()
  const existingData = translations.get(document.querySelector('rewatch-transcript'))
  existingData[value] = data
  replaceCues(data, value, label)
})

on('change', '[data-translate-language]', (event) => {
  const select = event.currentTarget as HTMLSelectElement
  const [value, label] = selectedLanuageCodeAndLabel()
  const exisingTranslation = translations.get(document.querySelector('rewatch-transcript'))[value]
  if (exisingTranslation) {
    replaceCues(exisingTranslation, value, label)
  } else {
    fire(select.form, 'submit')
  }
  const url = new URL(window.location.href)
  url.searchParams.set('lang', value)

  Turbo.visit(url, {frame: ['latest-transcript-summary']})
  Turbo.visit(url, {frame: ['summary-content-section-show']})
})

observe('rewatch-transcript', (transcript) => {
  document.querySelector('[data-translate]')?.classList.remove('hidden')
  const input = document.querySelector<HTMLInputElement>('input[data-translate-html-input]')
  if (!input) return
  const lang = transcript.getAttribute('lang')
  if (!lang) return
  defaultLanguageMap.set(transcript, lang)

  const html = Array.from(document.querySelectorAll('.js-transcript-cue'))
    .map((e) => e.outerHTML)
    .join('')
  input.value = html
  const data: TranslationData = {}
  data[lang] = html
  translations.set(transcript, data)

  const [value] = selectedLanuageCodeAndLabel()
  if (value && value !== lang) {
    document.querySelector('[data-translate-language]').dispatchEvent(new Event('change', {bubbles: true}))
  }
})

function selectedLanuageCodeAndLabel(): string[] {
  const select = document.querySelector<HTMLSelectElement>('[data-translate-language]')
  const selectedOption = select.selectedOptions[0]
  return [selectedOption.value, selectedOption.label]
}

const defaultLanguageMap = new WeakMap<Element, string>()

function replaceCues(html: string, languageCode: string, language: string) {
  const transcript = document.querySelector('rewatch-transcript')
  const defaultLanuguageCode = defaultLanguageMap.get(transcript)

  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')
  const cues = Array.from(doc.body.children)
  let i = 0
  for (const element of document.querySelectorAll('.js-transcript-cue')) {
    const newCue = cues[i]
    if (!newCue) continue
    element.textContent = newCue.textContent
    i++
  }

  ariaNotify(`Transcript language is now ${language}`)

  transcript.setAttribute('lang', languageCode)
  const url = new URL(window.location.href)
  if (languageCode === defaultLanuguageCode) {
    transcript.removeAttribute('translated')
    url.searchParams.delete('lang')
  } else {
    transcript.setAttribute('translated', '')
    url.searchParams.set('lang', languageCode)
  }

  window.history.replaceState({}, null, url.toString())
}

// Prioritize navigator.language if found
observe('[data-translate-language]', {
  constructor: HTMLSelectElement,
  initialize(select) {
    const preferredLanguages = navigator.languages || []
    if (preferredLanguages.length === 0) return
    const firstOption = select.options[0]
    for (const option of select.options) {
      if (!preferredLanguages.includes(option.value)) continue
      firstOption.after(option)
    }
  },
})

// Prioritize navigator.language if found
observe('[data-subtitles-language-menu]', {
  initialize(menu) {
    const preferredLanguages = navigator.languages || []
    if (preferredLanguages.length === 0) return
    const firstOption = menu.querySelector('[data-subtitles-default]')
    for (const option of menu.querySelectorAll('[data-subtitles-lang]')) {
      if (!preferredLanguages.includes(option.getAttribute('data-subtitles-lang'))) continue
      firstOption.after(option)
    }
  },
})

on(
  'details-menu-selected',
  'details-menu[data-subtitles-language-menu]',
  async (event) => {
    const rewatchVideo = document.querySelector('rewatch-video')
    await rewatchVideo.dataLoadedPromise

    const subtitlesControl = document.querySelector<HTMLButtonElement>('.js-toggle-captions-btn')
    const isOn = subtitlesControl.getAttribute('aria-pressed') === 'true'

    const lang = event.detail.relatedTarget.getAttribute('data-subtitles-lang')
    const label = event.detail.relatedTarget.getAttribute('data-subtitles-lang-label')
    const summary = document.querySelector('[data-subtitle-language-summary]')

    if (lang) {
      if (!isOn) {
        subtitlesControl.click()
        summary.setAttribute('aria-pressed', 'true')
      }
    } else if (isOn) {
      subtitlesControl.click()
      summary.setAttribute('aria-pressed', 'false')
      return
    }

    const defaultTrack = document.querySelector<HTMLTrackElement>('#default-caption-track')
    const url = new URL(defaultTrack.src, window.location.href)
    if (event.detail.relatedTarget.hasAttribute('data-subtitles-default')) {
      url.searchParams.delete('lang')
    } else {
      url.searchParams.set('lang', lang)
    }
    defaultTrack.src = url.toString()
    defaultTrack.label = label
    defaultTrack.srclang = lang
  },
  {capture: true}
)
