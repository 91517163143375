import {observe} from 'selector-observer'

observe('auto-complete', (el) => {
  el.addEventListener('loadstart', () => {
    el.classList.add('is-loading')
  })
  el.addEventListener('loadend', () => {
    el.classList.remove('is-loading')
  })
})
