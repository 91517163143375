function reportStats(info: unknown) {
  if (!('sendBeacon' in navigator)) return
  navigator.sendBeacon(
    '/stats',
    new Blob(
      [
        JSON.stringify(
          Object.assign(info, {
            location: document.location.href,
            referrer: document.referrer,
            // eslint-disable-next-line camelcase
            authenticity_token: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
          })
        ),
      ],
      {type: 'application/json'}
    )
  )
}

export {reportStats}
