// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {Turbo} from '@hotwired/turbo-rails'
import {on} from 'delegated-events'

on('ajax:success', '.js-update-button', (e) => {
  Turbo.cache.clear()
  const button = e.currentTarget
  const [data] = e.detail
  const firstButton = data.body.querySelector('button')
  button.replaceWith(...data.body.children)
  firstButton?.focus()
})
