import {on} from 'delegated-events'

on('click', '[data-scroll-to-active-annotation]', (e) => {
  const comment = document.getElementById(e.currentTarget.getAttribute('data-scroll-to-active-annotation'))
  const container = document.getElementById('comments-video-tab')
  const activeComments = container.querySelectorAll('[aria-current="true"]')
  setTimeout(() => {
    comment.scrollIntoView({behavior: 'smooth'})
    const activeContainer = comment.querySelector('[aria-current]')
    activeContainer.setAttribute('aria-current', 'true')

    for (const activeComment of activeComments) activeComment.setAttribute('aria-current', 'false')
  }, 0)
})
