import {on} from 'delegated-events'

on('click', 'a.js-merge-param', (event) => {
  event.preventDefault()
  const link = event.currentTarget as HTMLAnchorElement
  const url = new URL(window.location.href)
  const newParams = new URL(link.href).searchParams
  const resetParam = link.getAttribute('data-reset-param')
  if (resetParam) {
    url.searchParams.delete(resetParam)
  }
  for (const param of newParams.keys()) {
    url.searchParams.set(param, newParams.get(param))
  }
  window.location.href = url.toString()
})
