import {on} from 'delegated-events'
import {isFormField} from '../utils'

document.addEventListener('turbo:before-cache', () => {
  //collapse all open menus before caching
  for (const menuDetails of document.querySelectorAll<HTMLDetailsElement>('details[data-details-dropdown][open]')) {
    menuDetails.open = false
  }
})

let lastOpenDetails: HTMLDetailsElement | null = null

on(
  'toggle',
  '[data-details-dropdown]',
  (event) => {
    const details = event.currentTarget.closest('details')
    if (details.open) {
      const currentlyOpen = lastOpenDetails
      event.currentTarget.querySelector<HTMLElement>('[autofocus], a, button, input')?.focus()
      lastOpenDetails = details
      if (currentlyOpen) currentlyOpen.open = false
      document.addEventListener('keydown', closeDetailsOnEscape)

      // Just needs the slightest delay to paint the element before attempting to animate
      setTimeout(() => {
        details.setAttribute('data-ready-to-animate', '')
      }, 1)
    } else {
      if (lastOpenDetails && lastOpenDetails !== details) return
      details.querySelector('summary')?.focus()
      lastOpenDetails = null

      details.removeAttribute('data-ready-to-animate')
    }
  },
  {capture: true}
)

function closeDetailsOnEscape(event: KeyboardEvent) {
  if (isFormField(event.target as HTMLElement)) return
  if (event.key !== 'Escape' || !lastOpenDetails) return
  lastOpenDetails.open = false
  lastOpenDetails = null
  event.stopPropagation()
}

// Optional button to close details
on('click', '[data-close-details]', (event) => {
  const details = event.currentTarget.closest('details')
  if (!details) return
  details.open = false
})
