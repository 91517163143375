import {observe} from 'selector-observer'

const keyPressElements: HTMLElement[] = []

observe('.js-click-on-key', {
  constructor: HTMLElement,
  initialize(el) {
    keyPressElements.push(el)
  },
  remove(el) {
    keyPressElements.splice(keyPressElements.indexOf(el), 1)
  },
})

function handlePress(e: KeyboardEvent) {
  const target = e.target as HTMLElement
  if (['INPUT', 'SELECT', 'TRIX-EDITOR'].includes(target.tagName)) return

  for (const el of keyPressElements) {
    const code = el.getAttribute('data-code')
    const shift = el.hasAttribute('data-shift')

    // skip enter / space if target is a button
    if (target.matches('button:focus') && (code === 'Space' || code === 'Enter')) {
      continue
    }

    if (e.code === code && e.shiftKey === shift) {
      el.click()
    }
  }
}

document.addEventListener('keydown', handlePress)
