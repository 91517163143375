import {observe} from 'selector-observer'

export function flashMessage(title: string, kind: string) {
  const supportedKinds = ['warning', 'danger', 'error', 'alert', 'success', 'notice']

  if (title === null || title === '') return
  if (!supportedKinds.includes(kind)) return

  const flash = document
    .querySelector<HTMLTemplateElement>('template[data-flash-tpl]')
    .content.cloneNode(true) as HTMLElement

  flash.querySelector<HTMLElement>('[data-flash-title]').textContent = title
  flash.querySelector<HTMLElement>('[data-flash-coloring]').classList.add(...kindToColorClasses(kind))
  flash.querySelector<SVGElement>(`[data-flash-icon=${kindToIcon(kind)}]`).classList.remove('hidden')

  document.querySelector('[data-flashes]').append(flash)
}

function kindToColorClasses(kind: string) {
  switch (kind) {
    case 'success':
      return ['color:green', 'bg:green', 'border:green']
    case 'warning':
      return ['color:orange', 'bg:orange', 'border:orange']
    case 'error':
    case 'danger':
    case 'alert':
      return ['color:red', 'bg:red', 'border:red']
    default:
      return ['color:blue', 'bg:blue', 'border:blue']
  }
}

function kindToIcon(kind: string) {
  switch (kind) {
    case 'success':
    case 'warning':
    case 'error':
      return kind
    case 'danger':
    case 'alert':
      return 'error'
    default:
      return 'info'
  }
}

observe('[data-flash]', (el) => {
  if (el.getAttribute('rendered')) return

  el.setAttribute('rendered', 'true')
  flashMessage(el.getAttribute('data-flash'), el.getAttribute('data-flash-kind'))
})
