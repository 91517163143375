import {observe} from 'selector-observer'

let lastCallTime = Date.now()
const clickInterval = 1000

class AutoClick {
  clickableEl: HTMLElement

  constructor(el: HTMLElement) {
    this.clickableEl = el
    this.observeIntersection()
  }

  async observeIntersection() {
    const options: IntersectionObserverInit = {root: this.intersectionRoot}

    await intersection(this.clickableEl, options)

    // set an interval so we don't trigger many clicks simultaneously
    const now = Date.now()
    if (now - lastCallTime < clickInterval) {
      const delayTime = lastCallTime + clickInterval - now
      lastCallTime = now + delayTime
      await new Promise((resolve) => setTimeout(resolve, delayTime))
    } else {
      lastCallTime = now
    }

    this.clickableEl.classList.add('btn-loading')
    this.clickableEl.click()
  }

  get intersectionRoot() {
    const root = this.clickableEl.offsetParent
    return root && root.scrollHeight > root.clientHeight ? root : null
  }
}

function intersection(element: Element, options = {}) {
  return new Promise<void>((resolve) => {
    new IntersectionObserver(([entry], observer) => {
      if (!entry.isIntersecting) return
      observer.disconnect()
      resolve()
    }, options).observe(element)
  })
}

observe('[data-auto-click]', {
  constructor: HTMLElement,
  initialize(el) {
    if (el.getAttribute('data-auto-click') === 'immediately') {
      el.click()
    } else {
      new AutoClick(el)
    }
  },
})
