import {init, browserTracingIntegration} from '@sentry/browser'

if (process.env.NODE_ENV !== 'development') {
  // TODO: share the host prefix and project ID with errors controller as ENV variables
  init({
    dsn: 'https://0c6b242d906a4acbbac5bb9ebe2c6709@o391189.ingest.sentry.io/6147116',
    integrations: [browserTracingIntegration()],
    tunnel: '/errors/tunnel',
    tracesSampleRate: 0.25,
    environment: process.env.NODE_ENV,
    release: process.env.GIT_REV,
    allowUrls: ['rewatch.com'],
  })
}
